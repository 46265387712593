import { useState } from "react";
import { useAccountsApiClient, useAuthApiClient } from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { PrimaryButton } from "../../ui/Buttons";
import { FilteringDropdown } from "../../ui/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-light-svg-icons";

export const AdminViewAsCompanyPage = () => {
    const authApiClient = useAuthApiClient();
    const accountsApiClient = useAccountsApiClient();
    const [selectedCompany, setSelectedCompany] = useState<number>();
    const [loading, setLoading] = useState(false);

    const companiesQuery = useQuery({
        queryKey: ["staffCompanyList"],
        queryFn: async () => {
            return await accountsApiClient.accountsCompaniesList();
        },
        refetchOnWindowFocus: false,
    });

    const logInAsCompany = async () => {
        try {
            await authApiClient.authAdminViewAsCompanyCreate({
                adminViewAsCompanyRequest: {
                    companyId: selectedCompany,
                },
            });
            localStorage.clear();
            window.location.replace("/map");
            window.location.reload();
        } catch (e) {
            alert("Failed to create user account.");
        }
        setLoading(false);
    };

    return (
        <div className="w-full h-full">
            <div className="flex justify-between py-4 px-10 items-center">
                <h1 className="text-xl">View as company</h1>
            </div>
            <hr />

            <div className="w-full px-10 py-3">
                <p className="mt-2">
                    You can use this functionality to view the platform as a
                    certain company.
                </p>
                <div className="mt-2">
                    Notes:
                    <ul className="mt-1 list-disc list-inside">
                        <li>
                            This will create a new temporary user with read-only
                            permissions.
                        </li>
                        <li>
                            It will log you out of the current user and
                            automatically log you in as the temporary user.
                        </li>
                        <li>
                            It will be automatically deleted after 2 hours. You
                            can come back here and start over if that happens.
                        </li>
                    </ul>
                </div>

                <hr className="my-4" />

                <div className="flex items-center gap-4">
                    {!loading && companiesQuery.data ? (
                        <>
                            <div className="-mt-1">
                                <FilteringDropdown
                                    value={selectedCompany}
                                    onChange={(c) =>
                                        setSelectedCompany(c as number)
                                    }
                                    options={companiesQuery.data?.map((c) => ({
                                        id: c.id,
                                        displayName: c.name,
                                        searchString: c.name.toLowerCase(),
                                    }))}
                                />
                            </div>
                            <PrimaryButton
                                variant="lg"
                                onClick={logInAsCompany}
                            >
                                Start session
                            </PrimaryButton>
                        </>
                    ) : (
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon={faArrowsRotate}
                                className="h-4 animate-spin"
                            />
                            Loading
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
