import { GeoJsonLayer } from "@deck.gl/layers";
import {
    AerialImagesList,
    GeoJSONFeatureCollection,
    PipelineMapList,
} from "../../../apiClient/generated";
import { MAP_ZOOM_SHOW_DETAILS } from "../constants";
import { BitmapLayer } from "@deck.gl/layers";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import type { Feature, GeoJSON } from "geojson";
import * as turf from "@turf/turf";
import { PathStyleExtension } from "@deck.gl/extensions";

const clusterIconMapping = {
    marker: {
        x: 0,
        y: 0,
        width: 45,
        height: 20,
        mask: false,
    },
};

function clusterIconAtlas() {
    const canvas = document.createElement("canvas");
    canvas.width = 45;
    canvas.height = 20;
    const ctx = canvas.getContext("2d");

    // Draw white rounded rectangle
    ctx.fillStyle = "white";
    ctx.beginPath();
    ctx.roundRect(1, 1, 43, 18, 9);
    ctx.fill();

    // Draw black border
    ctx.strokeStyle = "#1F1F1F";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.roundRect(1, 1, 43, 18, 9);
    ctx.stroke();

    return canvas;
}

const infrastructureIconMapping = {
    marker: {
        x: 0,
        y: 0,
        width: 144,
        height: 144,
        mask: false,
    },
};

function infrastructureIconAtlas() {
    const canvas = document.createElement("canvas");
    canvas.width = 144;
    canvas.height = 144;
    const ctx = canvas.getContext("2d");

    // Scale everything up by 4
    ctx.scale(4, 4);

    // Draw black rounded rectangle background
    ctx.fillStyle = "#1F1F1F";
    ctx.beginPath();
    ctx.roundRect(9, 5, 18, 18, 9);
    ctx.fill();

    // Draw white border
    ctx.strokeStyle = "white";
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.roundRect(9.5, 5.5, 17, 17, 8.5);
    ctx.stroke();

    // Draw white house icon
    ctx.fillStyle = "white";
    ctx.beginPath();
    ctx.moveTo(16.25, 11.3125);
    ctx.lineTo(16.25, 13.9375);
    ctx.lineTo(18.875, 12.625);
    ctx.lineTo(18.875, 13.9375);
    ctx.lineTo(21.5, 12.625);
    ctx.lineTo(21.5, 17.4375);
    ctx.lineTo(14.5, 17.4375);
    ctx.lineTo(14.5, 11.3125);
    ctx.closePath();
    ctx.fill();

    return canvas;
}

export const InfrastructureLayer = (
    features: Feature[],
    overviewData: any,
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
    enableOverviews?: boolean,
    disableHighlight?: boolean,
) => {
    if (enableOverviews && zoomLevel < 8) {
        if (!overviewData) {
            return [];
        }

        let max = 0;
        let min = 1e6;
        overviewData.features.forEach((i) => {
            if (i.properties.count > max) {
                max = i.properties.count;
            }
            if (i.properties.count < min) {
                min = i.properties.count;
            }
        });

        return [
            new GeoJsonLayer({
                id: "infrastructure-overviews",
                data: overviewData || [],
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: false,
                visible,
                autoHighlight: true,
                onHover,
                getFillColor: (i) => [
                    0,
                    0,
                    0,
                    ((i.properties.count - min) / max) * 100 + 40,
                ],
                getLineWidth: 1,
                getLineColor: [0, 0, 0, 0],
            }),
        ];
    }

    const clusters = [];
    const sites = [];
    const sitesShapes = [];
    const groups = [];
    const equipment = [];

    for (const i of features) {
        if (i.properties.cluster) {
            clusters.push(i);
        } else if (i.properties.infraType == "SITE") {
            if (i.geometry.type === "Polygon") {
                sitesShapes.push(i);
                const center = turf.centroid(i.geometry);
                sites.push({
                    ...i,
                    geometry: center.geometry,
                });
            } else {
                sites.push(i);
            }
        } else if (i.properties.infraType == "EQUIPMENT_GROUP") {
            groups.push(i);
        } else {
            equipment.push(i);
        }
    }

    return [
        new GeoJsonLayer({
            id: "infrastructure-sites-shapes",
            data: { type: "FeatureCollection", features: sitesShapes },
            pointType: "icon",
            iconAtlas: infrastructureIconAtlas(),
            getIconSize: 36,
            getIcon: () => "marker",
            iconMapping: infrastructureIconMapping,
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            autoHighlight: !disableHighlight,
            visible: visible && zoomLevel > 14,
            onHover,
            highlightColor: [255, 255, 255, 20],
            getFillColor: [0, 0, 0, 40],
            lineWidthUnits: "pixels",
            getLineWidth: 1.5,
            getLineColor: [255, 255, 255, 255],
        }),
        new GeoJsonLayer({
            id: "infrastructure-groups",
            data: { type: "FeatureCollection", features: groups },
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            visible: visible && zoomLevel > 15.5,
            autoHighlight: !disableHighlight,
            onHover,
            highlightColor: [255, 255, 255, 30],
            getFillColor: [0, 0, 0, 0],
            getLineWidth: 1,
            getLineColor: [255, 255, 255, 255],
            getDashArray: [5, 5],
            dashJustified: true,
            dashGapPickable: true,
            extensions: [new PathStyleExtension({ dash: true })],
        }),
        new GeoJsonLayer({
            id: "infrastructure-equipment",
            data: { type: "FeatureCollection", features: equipment },
            pointType: "circle",
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            visible: visible && zoomLevel > 15.5,
            autoHighlight: !disableHighlight,
            onHover,
            highlightColor: [22, 119, 255, 100],
            getFillColor: [255, 255, 255, 255],
            getLineWidth: 1.5,
            getLineColor: [0, 0, 0, 255],
            getPointRadius: 5,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
        }),
        new GeoJsonLayer({
            id: "infrastructure-clusters",
            data: { type: "FeatureCollection", features: clusters },
            pointType: "icon+text",
            iconAtlas: clusterIconAtlas(),
            iconMapping: clusterIconMapping,
            getIcon: () => "marker",
            getIconSize: 15,
            getPosition: (d) => d.geometry.coordinates,
            getFillColor: [0, 0, 0, 255],
            pickable: !disableHighlight,
            autoHighlight: !disableHighlight,
            visible,
            getText: (i) => `${i.properties.point_count_abbreviated}`,
            getTextSize: 12,
            getTextColor: [0, 0, 0, 255],
            onHover,
        }),
        new GeoJsonLayer({
            id: "infrastructure-sites-points",
            data: { type: "FeatureCollection", features: sites },
            pointType: "icon",
            iconAtlas: infrastructureIconAtlas(),
            getIconSize: 40,
            getIcon: () => "marker",
            iconMapping: infrastructureIconMapping,
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            autoHighlight: !disableHighlight,
            visible,
            onHover,
        }),
    ];
};

export const PipelineLayer = (
    data: PipelineMapList[],
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
) => {
    return [
        new GeoJsonLayer({
            id: "pipelines",
            data: createFeatureCollection(data),
            pointType: "circle",
            filled: true,
            lineWidthUnits: "pixels",
            stroked: true,
            pickable: true,
            lineCapRounded: true,
            lineJointRounded: true,
            visible,
            autoHighlight: true,
            onHover,
            highlightColor: [0, 0, 0, 30],
            getLineWidth: 1.5,
            getLineColor: [0, 0, 0, 230],
        }),
    ];
};

export const PipelineV2Layers = (
    data: PipelineMapList[],
    overviews: GeoJSONFeatureCollection,
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
) => {
    const pipelineLayers = [];
    if (zoomLevel <= 10) {
        pipelineLayers.push(
            new GeoJsonLayer({
                id: "pipelineOverviews",
                data: overviews as GeoJSON,
                pointType: "circle",
                filled: true,
                lineWidthUnits: "pixels",
                stroked: false,
                visible,
                autoHighlight: true,
                onHover,
                highlightColor: [0, 0, 0, 120],
                getLineWidth: 1,
            }),
        );
    }

    if (zoomLevel > 10) {
        pipelineLayers.push(
            new GeoJsonLayer({
                id: "pipelines-v2",
                data: createFeatureCollection(data),
                pointType: "circle",
                filled: true,
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: true,
                lineCapRounded: true,
                lineJointRounded: true,
                visible,
                autoHighlight: true,
                onHover,
                highlightColor: [0, 0, 0, 30],
                getLineWidth: zoomLevel < 14.5 ? 6 : 4,
                getLineColor: [0, 0, 0, 230],
            }),
        );
        pipelineLayers.push(
            new GeoJsonLayer({
                id: "pipelines-v2-drawing-center",
                data: createFeatureCollection(data),
                pointType: "circle",
                filled: true,
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: false,
                lineCapRounded: true,
                lineJointRounded: true,
                visible,
                autoHighlight: true,
                onHover,
                highlightColor: [0, 0, 0, 30],
                getLineWidth: zoomLevel < 14.5 ? 2 : 1,
                getLineColor: [255, 255, 255, 230],
            }),
        );
    }

    return pipelineLayers;
};

export const AerialImagesLayer = (
    data: AerialImagesList[],
    visible: boolean,
    zoomLevel: number,
) => {
    if (!data) {
        return [];
    }

    if (zoomLevel < MAP_ZOOM_SHOW_DETAILS) {
        return [];
    }

    // Filter images inside map view and return image array
    return data.map((item) => {
        return new BitmapLayer({
            id: `aerial_image_${item.id}`,
            // FIXME: improve serializer definition of bounds
            bounds: item.bounds as any,
            image: item.image,
            visible: visible,
        });
    });
};

export const SiteLayer = (
    features: Feature[],
    overviewData: any,
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
    enableOverviews?: boolean,
    disableHighlight?: boolean,
) => {
    if (enableOverviews && zoomLevel < 8) {
        if (!overviewData) {
            return [];
        }

        let max = 0;
        let min = 1e6;
        overviewData.features.forEach((i) => {
            if (i.properties.count > max) {
                max = i.properties.count;
            }
            if (i.properties.count < min) {
                min = i.properties.count;
            }
        });

        return [
            new GeoJsonLayer({
                id: "infrastructure-sites-overviews",
                data: overviewData || [],
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: false,
                visible,
                autoHighlight: true,
                onHover,
                getFillColor: (i) => [
                    0,
                    0,
                    0,
                    ((i.properties.count - min) / max) * 100 + 40,
                ],
                getLineWidth: 1,
                getLineColor: [0, 0, 0, 0],
            }),
        ];
    }

    const clusters = [];
    const sites = [];
    const sitesShapes = [];

    for (const i of features) {
        if (i.properties.cluster) {
            clusters.push(i);
        } else {
            if (i.geometry.type === "Polygon") {
                sitesShapes.push(i);
                const center = turf.centroid(i.geometry);
                sites.push({
                    ...i,
                    geometry: center.geometry,
                });
            } else {
                sites.push(i);
            }
        }
    }

    return [
        new GeoJsonLayer({
            id: "infrastructure-sites-shapes",
            data: { type: "FeatureCollection", features: sitesShapes },
            pointType: "icon",
            iconAtlas: infrastructureIconAtlas(),
            getIconSize: 36,
            getIcon: () => "marker",
            iconMapping: infrastructureIconMapping,
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            autoHighlight: !disableHighlight,
            visible: visible && zoomLevel > 14,
            onHover,
            highlightColor: [255, 255, 255, 20],
            getFillColor: [0, 0, 0, 40],
            lineWidthUnits: "pixels",
            getLineWidth: 1.5,
            getLineColor: [255, 255, 255, 255],
        }),
        new GeoJsonLayer({
            id: "infrastructure-sites-clusters",
            data: { type: "FeatureCollection", features: clusters },
            pointType: "icon+text",
            iconAtlas: clusterIconAtlas(),
            iconMapping: clusterIconMapping,
            getIcon: () => "marker",
            getIconSize: 15,
            getPosition: (d) => d.geometry.coordinates,
            getFillColor: [0, 0, 0, 255],
            pickable: !disableHighlight,
            autoHighlight: !disableHighlight,
            visible,
            getText: (i) => `${i.properties.point_count_abbreviated}`,
            getTextSize: 12,
            getTextColor: [0, 0, 0, 255],
            onHover,
        }),
        new GeoJsonLayer({
            id: "infrastructure-sites-points",
            data: { type: "FeatureCollection", features: sites },
            pointType: "icon",
            iconAtlas: infrastructureIconAtlas(),
            getIconSize: 40,
            getIcon: () => "marker",
            iconMapping: infrastructureIconMapping,
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            autoHighlight: !disableHighlight,
            visible,
            onHover,
        }),
    ];
};

export const EquipmentLayer = (
    features: Feature[],
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
    disableHighlight?: boolean,
) => {
    const groups = [];
    const equipment = [];

    for (const i of features) {
        if (i.properties.equipmentType == "EQUIPMENT_GROUP") {
            groups.push(i);
        } else {
            equipment.push(i);
        }
    }

    return [
        new GeoJsonLayer({
            id: "infrastructure-equipment-groups",
            data: { type: "FeatureCollection", features: groups },
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            visible: visible && zoomLevel > 15.5,
            autoHighlight: !disableHighlight,
            onHover,
            highlightColor: [255, 255, 255, 30],
            getFillColor: [0, 0, 0, 0],
            getLineWidth: 1,
            getLineColor: [255, 255, 255, 255],
            getDashArray: [5, 5],
            dashJustified: true,
            dashGapPickable: true,
            extensions: [new PathStyleExtension({ dash: true })],
        }),
        new GeoJsonLayer({
            id: "infrastructure-equipment",
            data: { type: "FeatureCollection", features: equipment },
            pointType: "circle",
            filled: true,
            stroked: true,
            pickable: !disableHighlight,
            visible: visible && zoomLevel > 14.5,
            autoHighlight: !disableHighlight,
            onHover,
            highlightColor: [22, 119, 255, 100],
            getFillColor: [255, 255, 255, 255],
            getLineWidth: 1.5,
            getLineColor: [0, 0, 0, 255],
            getPointRadius: 5,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
        }),
    ];
};
