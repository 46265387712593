/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EquipmentGeometryGeometriesInner } from './EquipmentGeometryGeometriesInner';
import {
    EquipmentGeometryGeometriesInnerFromJSON,
    EquipmentGeometryGeometriesInnerFromJSONTyped,
    EquipmentGeometryGeometriesInnerToJSON,
} from './EquipmentGeometryGeometriesInner';

/**
 * 
 * @export
 * @interface EquipmentGeometry
 */
export interface EquipmentGeometry {
    /**
     * 
     * @type {string}
     * @memberof EquipmentGeometry
     */
    type: EquipmentGeometryTypeEnum;
    /**
     * 
     * @type {Array<EquipmentGeometryGeometriesInner>}
     * @memberof EquipmentGeometry
     */
    geometries: Array<EquipmentGeometryGeometriesInner>;
}


/**
 * @export
 */
export const EquipmentGeometryTypeEnum = {
    GeometryCollection: 'GeometryCollection'
} as const;
export type EquipmentGeometryTypeEnum = typeof EquipmentGeometryTypeEnum[keyof typeof EquipmentGeometryTypeEnum];


/**
 * Check if a given object implements the EquipmentGeometry interface.
 */
export function instanceOfEquipmentGeometry(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('geometries' in value)) return false;
    return true;
}

export function EquipmentGeometryFromJSON(json: any): EquipmentGeometry {
    return EquipmentGeometryFromJSONTyped(json, false);
}

export function EquipmentGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGeometry {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'geometries': ((json['geometries'] as Array<any>).map(EquipmentGeometryGeometriesInnerFromJSON)),
    };
}

export function EquipmentGeometryToJSON(value?: EquipmentGeometry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'geometries': ((value['geometries'] as Array<any>).map(EquipmentGeometryGeometriesInnerToJSON)),
    };
}

