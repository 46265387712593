import { useMemo, useCallback } from "react";
import {
    MapPlumeImagesListDataSourceEnum,
    PlumeImage,
    MapEmissionRecordsListRequest,
    PlumeOulines,
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    MapPlumeOutlinesListRequest,
    MapEmissionRecordsListExcludeEmissionStatusEnum,
} from "../../../apiClient/generated";
import { useMapApiClient } from "../../../hooks";
import {
    getBinForRateOrConcentration,
    MAP_ZOOM_SHOW_DETAILS,
} from "../constants";
import { DataPointMapWithBin, EmissionRecordMapWithBin } from "../CustomTypes";
import { useMapDataLoader } from "./dataLoader";
import Supercluster from "supercluster";
import {
    EmissionImagesLayer,
    EmissionLayerStyle,
    EmissionRecordsLayers,
    PlumeOutlinesLayer,
} from "../layers/emissions";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import { useMap } from "./mapState";
import { useMapData } from "./mapDataAndFilters";
import { cleanFilters } from "./utils";
import { FilterByAreaDef } from "../hooks";

/**
 * Emission records: custom hook that loads emissions-related
 * data (points and plume images) based on filter values and
 * returns map layers for display.
 */
export const useEmissionRecordsOnMap = (
    enabled: boolean,
    dataName: string,
    enableOverviews: boolean,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        providerWithSource?:
            | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
            | "all";
        dataSource?: MapPlumeImagesListDataSourceEnum[];
        excludeEmissionStatus?: MapEmissionRecordsListExcludeEmissionStatusEnum[];
    },
    layerStyle: EmissionLayerStyle,
    filterByArea?: FilterByAreaDef,
) => {
    const apiClient = useMapApiClient();
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const {
        selectedContext,
        mapSettings: { plumeOpacity, showPlumes },
    } = useMapData("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);

    const emissionsDataLoader = useMapDataLoader<EmissionRecordMapWithBin>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapEmissionRecordsListRequest>(filters);
                if (shortCircuit) {
                    return {
                        results: [] as EmissionRecordMapWithBin[],
                    };
                }
                const response = await apiClient.mapEmissionRecordsList(
                    {
                        ...cleanFilterValues,
                        providerWithSource: cleanFilterValues.providerWithSource
                            ? (JSON.stringify(
                                  cleanFilterValues.providerWithSource,
                              ) as any)
                            : undefined,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
                return {
                    ...response,
                    results: response.results.map((i) => {
                        return {
                            ...i,
                            mapDotSize: getBinForRateOrConcentration(
                                i.detectedRate / 1000,
                                i.concentration,
                            ).size,
                        };
                    }),
                };
            },
            [filters, filterByArea, apiClient],
        ),
        zoomToStartFetching: 1,
        enabled:
            enabled &&
            (filters.providerWithSource.length > 0 ||
                filters.providerWithSource === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });

    const plumeImagesDataLoader = useMapDataLoader<PlumeImage>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapEmissionRecordsListRequest>(filters);
                if (shortCircuit) {
                    return {
                        results: [] as PlumeImage[],
                    };
                }
                return await apiClient.mapPlumeImagesList(
                    {
                        ...cleanFilterValues,
                        infrastructure: cleanFilterValues.infrastructure
                            ? [cleanFilterValues.infrastructure]
                            : undefined,
                        providerWithSource: cleanFilterValues.providerWithSource
                            ? (JSON.stringify(
                                  cleanFilterValues.providerWithSource,
                              ) as any)
                            : undefined,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [filters, filterByArea, apiClient],
        ),
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.providerWithSource.length > 0 ||
                filters.providerWithSource === "all") &&
            showPlumes,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
        unloadZoomLevel: MAP_ZOOM_SHOW_DETAILS - 1,
    });

    const plumeOutlinesDataLoader = useMapDataLoader<PlumeOulines>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapPlumeOutlinesListRequest>(filters);
                if (shortCircuit) {
                    return {
                        results: [] as PlumeOulines[],
                    };
                }
                return await apiClient.mapPlumeOutlinesList(
                    {
                        ...cleanFilterValues,
                        providerWithSource: cleanFilterValues.providerWithSource
                            ? (JSON.stringify(
                                  cleanFilterValues.providerWithSource,
                              ) as any)
                            : undefined,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [filters, filterByArea, apiClient],
        ),
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.providerWithSource.length > 0 ||
                filters.providerWithSource === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
        unloadZoomLevel: MAP_ZOOM_SHOW_DETAILS - 1,
    });

    // Set up emission clustering
    const clusterData = useMemo(() => {
        const index = new Supercluster({
            radius: 50,
            maxZoom: 9,
            map: (props) => ({
                highestRate: props.detectedRate,
                highestConcentration: props.concentration,
            }),
            reduce: (accumulated, props) => {
                accumulated.highestRate = Math.max(
                    accumulated.highestRate,
                    props.highestRate,
                );
                accumulated.highestConcentration = Math.max(
                    accumulated.highestConcentration,
                    props.highestConcentration,
                );
            },
        });
        index.load(
            emissionsDataLoader.data.map(
                (i) =>
                    ({
                        type: "Feature",
                        geometry: i.location,
                        properties: i,
                    }) as any,
            ),
        );
        return index;
    }, [emissionsDataLoader.data]);

    const clusteredPoints = useMemo(() => {
        if (!areaOnScreen) {
            return [];
        }

        if (currentZoom >= 9) {
            return [];
        }

        // Annotate zoom level in which cluster is broken down.
        return clusterData
            .getClusters([-180, -90, 180, 90], currentZoom)
            .map((i: any) => {
                if (i.properties.cluster) {
                    i.properties.zoom = clusterData.getClusterExpansionZoom(
                        i.properties.cluster_id,
                    );
                    i.properties.tooltip = "Click to zoom in";
                    i.properties.mapDotSize = getBinForRateOrConcentration(
                        i.properties.highestRate / 1000,
                        i.properties.highestConcentration,
                    ).size;
                }
                return i;
            });
    }, [clusterData, currentZoom, areaOnScreen]);

    // Layers
    const emissionLayers = useMemo(() => {
        return [
            EmissionRecordsLayers(
                createFeatureCollection(emissionsDataLoader.data).features,
                enabled,
                currentZoom,
                dataName,
                layerStyle,
                // Only send clustered data if overviews are enabled.
                enableOverviews ? clusteredPoints : undefined,
            ),
        ];
    }, [
        emissionsDataLoader.data,
        enabled,
        currentZoom,
        layerStyle,
        dataName,
        enableOverviews,
        clusteredPoints,
    ]);

    const plumeLayers = useMemo(() => {
        const layers = [];
        const visiblePlumeOutlines = plumeOutlinesDataLoader.data.filter(
            (outline) => {
                if (showPlumes && outline.hasPlume) {
                    return false;
                }
                if (selectedContext.dataPointId) {
                    return selectedContext.dataPointId === outline.id;
                }
                if (selectedContext.emissionRecordId) {
                    return outline.emissionRecordIds.some(
                        (i) => i === selectedContext.emissionRecordId,
                    );
                }
                return true;
            },
        );

        // Plume outlines first, so they render at the bottom
        if (visiblePlumeOutlines.length > 0) {
            layers.push(
                PlumeOutlinesLayer(
                    visiblePlumeOutlines,
                    `plume_outlines_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }

        // Then plume images, only if they are enabled
        if (selectedContext.relatedPlume !== null && showPlumes) {
            layers.push(
                EmissionImagesLayer(
                    selectedContext.relatedPlume
                        ? plumeImagesDataLoader.data.filter(
                              (i) => i.id === selectedContext.relatedPlume,
                          )
                        : plumeImagesDataLoader.data,
                    `plumes_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }
        return layers;
    }, [
        enabled,
        plumeImagesDataLoader.data,
        plumeOutlinesDataLoader.data,
        currentZoom,
        selectedContext,
        plumeOpacity,
        showPlumes,
        dataName,
    ]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissionLayers,
        plumeLayers,
    };
};

/**
 * Public data points.
 *
 * At this point this is just a copy and paste of the method above
 * minus the clustering code and with changes to accomodate
 * the DataPoint model.
 *
 * // FIXME: Build a re-usable thing and remove duplication.
 */
export const usePublicDataPointsOnMap = (
    enabled: boolean,
    dataName: string,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        providerWithSource?:
            | AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
            | "all";
        dataSource?: MapPlumeImagesListDataSourceEnum[];
    },
    layerStyle: EmissionLayerStyle,
    filterByArea?: FilterByAreaDef,
) => {
    const apiClient = useMapApiClient();
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const {
        selectedContext,
        mapSettings: { plumeOpacity, showPlumes },
    } = useMapData("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);

    const emissionsDataLoader = useMapDataLoader<DataPointMapWithBin>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapEmissionRecordsListRequest>(filters);
                if (shortCircuit) {
                    return {
                        results: [] as DataPointMapWithBin[],
                    };
                }
                const response = await apiClient.mapPublicEmissionsList(
                    {
                        ...cleanFilterValues,
                        infrastructure: cleanFilterValues.infrastructure
                            ? [cleanFilterValues.infrastructure]
                            : undefined,
                        providerWithSource: cleanFilterValues.providerWithSource
                            ? (JSON.stringify(
                                  cleanFilterValues.providerWithSource,
                              ) as any)
                            : undefined,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
                return {
                    ...response,
                    results: response.results.map((i) => {
                        return {
                            ...i,
                            mapDotSize: getBinForRateOrConcentration(
                                i.detectedRate / 1000,
                                i.concentration,
                            ).size,
                        };
                    }),
                };
            },
            [filters, filterByArea, apiClient],
        ),
        zoomToStartFetching: 1,
        enabled:
            enabled &&
            (filters.providerWithSource.length > 0 ||
                filters.providerWithSource === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });

    const plumeImagesDataLoader = useMapDataLoader<PlumeImage>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapEmissionRecordsListRequest>(filters);
                if (shortCircuit) {
                    return {
                        results: [] as PlumeImage[],
                    };
                }
                return await apiClient.mapPublicPlumeImagesList(
                    {
                        ...cleanFilterValues,
                        infrastructure: cleanFilterValues.infrastructure
                            ? [cleanFilterValues.infrastructure]
                            : undefined,
                        providerWithSource: cleanFilterValues.providerWithSource
                            ? (JSON.stringify(
                                  cleanFilterValues.providerWithSource,
                              ) as any)
                            : undefined,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [filters, filterByArea, apiClient],
        ),
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.providerWithSource.length > 0 ||
                filters.providerWithSource === "all") &&
            showPlumes,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
        unloadZoomLevel: MAP_ZOOM_SHOW_DETAILS - 1,
    });

    const plumeOutlinesDataLoader = useMapDataLoader<PlumeOulines>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapPlumeOutlinesListRequest>(filters);
                if (shortCircuit) {
                    return {
                        results: [] as PlumeOulines[],
                    };
                }
                return await apiClient.mapPublicPlumeOutlinesList(
                    {
                        ...cleanFilterValues,
                        providerWithSource: cleanFilterValues.providerWithSource
                            ? (JSON.stringify(
                                  cleanFilterValues.providerWithSource,
                              ) as any)
                            : undefined,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [filters, filterByArea, apiClient],
        ),
        zoomToStartFetching: MAP_ZOOM_SHOW_DETAILS,
        enabled:
            enabled &&
            (filters.providerWithSource.length > 0 ||
                filters.providerWithSource === "all"),
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
        unloadZoomLevel: MAP_ZOOM_SHOW_DETAILS - 1,
    });

    // Layers
    const emissionLayers = useMemo(() => {
        return [
            EmissionRecordsLayers(
                createFeatureCollection(emissionsDataLoader.data).features,
                enabled,
                currentZoom,
                dataName,
                layerStyle,
                undefined,
            ),
        ];
    }, [emissionsDataLoader.data, enabled, currentZoom, layerStyle, dataName]);

    const plumeLayers = useMemo(() => {
        const layers = [];
        const visiblePlumeOutlines = plumeOutlinesDataLoader.data.filter(
            (outline) => {
                if (showPlumes && outline.hasPlume) {
                    return false;
                }
                if (selectedContext.dataPointId) {
                    return selectedContext.dataPointId === outline.id;
                }
                if (selectedContext.emissionRecordId) {
                    return outline.emissionRecordIds.some(
                        (i) => i === selectedContext.emissionRecordId,
                    );
                }
                return true;
            },
        );

        // Plume outlines first, so they render at the bottom
        if (visiblePlumeOutlines.length > 0) {
            layers.push(
                PlumeOutlinesLayer(
                    visiblePlumeOutlines,
                    `plume_outlines_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }

        // Then plume images, only if they are enabled
        if (selectedContext.relatedPlume !== null && showPlumes) {
            layers.push(
                EmissionImagesLayer(
                    selectedContext.relatedPlume
                        ? plumeImagesDataLoader.data.filter(
                              (i) => i.id === selectedContext.relatedPlume,
                          )
                        : plumeImagesDataLoader.data,
                    `plumes_${dataName}`,
                    enabled,
                    currentZoom,
                    plumeOpacity,
                ),
            );
        }
        return layers;
    }, [
        enabled,
        plumeImagesDataLoader.data,
        plumeOutlinesDataLoader.data,
        currentZoom,
        selectedContext,
        plumeOpacity,
        showPlumes,
        dataName,
    ]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissionLayers,
        plumeLayers,
    };
};
