/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipmentGeometryGeometriesInnerOneOf1
 */
export interface EquipmentGeometryGeometriesInnerOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof EquipmentGeometryGeometriesInnerOneOf1
     */
    type: EquipmentGeometryGeometriesInnerOneOf1TypeEnum;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof EquipmentGeometryGeometriesInnerOneOf1
     */
    coordinates: Array<Array<number>>;
}


/**
 * @export
 */
export const EquipmentGeometryGeometriesInnerOneOf1TypeEnum = {
    LineString: 'LineString'
} as const;
export type EquipmentGeometryGeometriesInnerOneOf1TypeEnum = typeof EquipmentGeometryGeometriesInnerOneOf1TypeEnum[keyof typeof EquipmentGeometryGeometriesInnerOneOf1TypeEnum];


/**
 * Check if a given object implements the EquipmentGeometryGeometriesInnerOneOf1 interface.
 */
export function instanceOfEquipmentGeometryGeometriesInnerOneOf1(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('coordinates' in value)) return false;
    return true;
}

export function EquipmentGeometryGeometriesInnerOneOf1FromJSON(json: any): EquipmentGeometryGeometriesInnerOneOf1 {
    return EquipmentGeometryGeometriesInnerOneOf1FromJSONTyped(json, false);
}

export function EquipmentGeometryGeometriesInnerOneOf1FromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGeometryGeometriesInnerOneOf1 {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'coordinates': json['coordinates'],
    };
}

export function EquipmentGeometryGeometriesInnerOneOf1ToJSON(value?: EquipmentGeometryGeometriesInnerOneOf1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

