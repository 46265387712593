import { Equipment } from "../../../apiClient/generated";

export interface EquipmentTreeData extends Equipment {
    children?: EquipmentTreeData[];
}

export const convertToNestedTree = (
    items: Equipment[],
): EquipmentTreeData[] => {
    const itemMap: Record<string, EquipmentTreeData> = {};

    // Step 1: Create a map of items keyed by their IDs
    items.forEach((item) => {
        itemMap[item.id] = {
            ...item,
            children: [],
        };
    });

    // Step 2: Assign each item to its parent's children array
    items.forEach((item) => {
        if (item.parent && itemMap[item.parent]) {
            const parent = itemMap[item.parent];
            if (!parent.children) {
                parent.children = [];
            }
            parent.children.push(itemMap[item.id]); // Push itemMap[item.id] instead of item
        }
    });

    // Step 3: Form the tree structure starting from root items
    const rootItems: EquipmentTreeData[] = [];
    items.forEach((item) => {
        if (!item.parent) {
            rootItems.push(itemMap[item.id]); // Push itemMap[item.id] instead of item
        }
    });

    return rootItems;
};
