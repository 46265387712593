import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import * as turf from "@turf/turf";
import { MultiPolygon, Polygon } from "geojson";

/**
 * Use map data loader: custom hook that loads data for
 * map areas after a certain zoom level and caches the results.
 */
interface MapDataLoaderProps<T> {
    areaOnScreen: any;
    zoom: number;
    zoomToStartFetching: number;
    enabled: boolean;
    fetchFn: (
        areaToFetch: Polygon | MultiPolygon,
        abortSignal: AbortSignal,
        cursor?: string,
    ) => Promise<{
        next?: string;
        previous?: string;
        results: T[];
    }>;
    areaFilter?: Polygon | MultiPolygon;
    unloadZoomLevel?: number;
}

export const useMapDataLoader = <T>({
    areaOnScreen,
    zoom,
    zoomToStartFetching,
    enabled,
    fetchFn,
    areaFilter,
    unloadZoomLevel,
}: MapDataLoaderProps<T>) => {
    const abortControllerRef = useRef<AbortController | null>(null);
    // Data
    const [data, setData] = useState<T[]>([]);
    // Data state
    const [loading, setLoading] = useState(0);
    // Stores a polygon with all areas that are currently loading.
    const [areaLoading, setAreaLoading] = useState();
    // Stores a polygon with all areas that are already loaded.
    const [areaLoaded, setAreaLoaded] = useState();
    // Compute area in use
    const areaInUse = useMemo(() => {
        let area;
        if (areaLoaded && areaLoading) {
            area = turf.union(areaLoaded, areaLoading);
        } else if (areaLoading) {
            area = areaLoading;
        } else if (areaLoaded) {
            area = areaLoaded;
        }
        return area;
    }, [areaLoading, areaLoaded]);

    const fetchAllPages = useCallback(
        async (areaToFetch?: any) => {
            let response = undefined;
            let nextPageCursor = undefined;

            do {
                // Check if the request has been aborted before making the call
                if (abortControllerRef.current?.signal.aborted) {
                    throw new Error("AbortError");
                }

                response = await fetchFn(
                    areaToFetch,
                    abortControllerRef.current?.signal,
                    nextPageCursor,
                );

                // Append new results to existing data
                setData((prevData) => [...prevData, ...response.results]);

                // If there's another page, get the cursor from the next URL
                if (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    nextPageCursor = parameters.get("cursor");
                } else {
                    nextPageCursor = undefined;
                }
            } while (nextPageCursor);
        },
        [fetchFn],
    );

    // Helper function to reset state and abort ongoing requests
    const resetState = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        setData([]);
        setLoading(0);
        setAreaLoaded(undefined);
        setAreaLoading(undefined);
    }, []);

    // Reset state when fetchFn changes
    useEffect(() => {
        resetState();
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [fetchFn, areaFilter, resetState]);

    // If unloadZoomLevel is set, erase previously fetched
    // data when the zoom level is greater than that.
    useEffect(() => {
        if (unloadZoomLevel && zoom < unloadZoomLevel && data.length > 0) {
            resetState();
        }
    }, [zoom, unloadZoomLevel, data.length, resetState]);

    // Load data when screen moves.
    useEffect(() => {
        const loadData = async () => {
            try {
                if (!areaOnScreen) {
                    return;
                }
                // Calculate screen bounds as GeoJSON polygon
                // and compute different with data already loaded.
                let areaToFetch = Object.assign({}, areaOnScreen);
                if (areaFilter) {
                    areaToFetch = turf.intersect(areaFilter, areaToFetch);
                }
                if (!areaToFetch) {
                    return;
                }
                if (areaInUse) {
                    areaToFetch = turf.difference(areaToFetch, areaInUse);
                }
                if (!areaToFetch) {
                    return;
                }

                // Set loading status
                setLoading((loading) => loading + 1);
                setAreaLoading((area) => {
                    return area ? turf.union(area, areaToFetch) : areaToFetch;
                });

                // Load data here
                await fetchAllPages(areaToFetch.geometry);

                // setArea only if success
                setAreaLoading((area) =>
                    area ? turf.difference(area, areaToFetch) : undefined,
                );
                setAreaLoaded((area) =>
                    area ? turf.union(area, areaToFetch) : areaToFetch,
                );
                setLoading((loading) => loading - 1);
            } catch (e) {
                resetState();
                // Throw error if this wasn't an aborted or cancelled request.
                if (!["AbortError", "FetchError"].includes(e.name)) {
                    throw e;
                }
            }
        };

        // Load all data in area if zoom > zoomToStartFetching
        if (zoom > zoomToStartFetching && enabled) {
            loadData();
        }
    }, [
        enabled,
        zoom,
        zoomToStartFetching,
        areaOnScreen,
        areaFilter,
        fetchAllPages,
        areaInUse,
        resetState,
    ]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    return {
        loading: loading > 0,
        data,
        areaLoaded,
        areaLoading,
    };
};
