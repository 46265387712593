/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemShape } from './AdminInfrastructureImportItemShape';
import {
    AdminInfrastructureImportItemShapeFromJSON,
    AdminInfrastructureImportItemShapeFromJSONTyped,
    AdminInfrastructureImportItemShapeToJSON,
} from './AdminInfrastructureImportItemShape';
import type { EpaNotificationLocation } from './EpaNotificationLocation';
import {
    EpaNotificationLocationFromJSON,
    EpaNotificationLocationFromJSONTyped,
    EpaNotificationLocationToJSON,
} from './EpaNotificationLocation';

/**
 * Serializer used by map that returns the least amount of
 * information necessary to render the map.
 * @export
 * @interface SiteMapList
 */
export interface SiteMapList {
    /**
     * 
     * @type {string}
     * @memberof SiteMapList
     */
    readonly id: string;
    /**
     * 
     * @type {EpaNotificationLocation}
     * @memberof SiteMapList
     */
    location: EpaNotificationLocation;
    /**
     * 
     * @type {AdminInfrastructureImportItemShape}
     * @memberof SiteMapList
     */
    shape?: AdminInfrastructureImportItemShape;
}

/**
 * Check if a given object implements the SiteMapList interface.
 */
export function instanceOfSiteMapList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('location' in value)) return false;
    return true;
}

export function SiteMapListFromJSON(json: any): SiteMapList {
    return SiteMapListFromJSONTyped(json, false);
}

export function SiteMapListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteMapList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'location': EpaNotificationLocationFromJSON(json['location']),
        'shape': json['shape'] == null ? undefined : AdminInfrastructureImportItemShapeFromJSON(json['shape']),
    };
}

export function SiteMapListToJSON(value?: Omit<SiteMapList, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': EpaNotificationLocationToJSON(value['location']),
        'shape': AdminInfrastructureImportItemShapeToJSON(value['shape']),
    };
}

