/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SiteMapList } from './SiteMapList';
import {
    SiteMapListFromJSON,
    SiteMapListFromJSONTyped,
    SiteMapListToJSON,
} from './SiteMapList';

/**
 * 
 * @export
 * @interface PaginatedSiteMapListList
 */
export interface PaginatedSiteMapListList {
    /**
     * 
     * @type {string}
     * @memberof PaginatedSiteMapListList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSiteMapListList
     */
    previous?: string;
    /**
     * 
     * @type {Array<SiteMapList>}
     * @memberof PaginatedSiteMapListList
     */
    results: Array<SiteMapList>;
}

/**
 * Check if a given object implements the PaginatedSiteMapListList interface.
 */
export function instanceOfPaginatedSiteMapListList(value: object): boolean {
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedSiteMapListListFromJSON(json: any): PaginatedSiteMapListList {
    return PaginatedSiteMapListListFromJSONTyped(json, false);
}

export function PaginatedSiteMapListListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedSiteMapListList {
    if (json == null) {
        return json;
    }
    return {
        
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(SiteMapListFromJSON)),
    };
}

export function PaginatedSiteMapListListToJSON(value?: PaginatedSiteMapListList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(SiteMapListToJSON)),
    };
}

