/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMapLocation } from './DataPointMapLocation';
import {
    DataPointMapLocationFromJSON,
    DataPointMapLocationFromJSONTyped,
    DataPointMapLocationToJSON,
} from './DataPointMapLocation';
import type { EquipmentGeometry } from './EquipmentGeometry';
import {
    EquipmentGeometryFromJSON,
    EquipmentGeometryFromJSONTyped,
    EquipmentGeometryToJSON,
} from './EquipmentGeometry';
import type { EquipmentTypeEnum } from './EquipmentTypeEnum';
import {
    EquipmentTypeEnumFromJSON,
    EquipmentTypeEnumFromJSONTyped,
    EquipmentTypeEnumToJSON,
} from './EquipmentTypeEnum';

/**
 * Equipment serializer.
 * @export
 * @interface Equipment
 */
export interface Equipment {
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    owner?: number;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    site?: string;
    /**
     * Retrieves all active flags for current user.
     * @type {string}
     * @memberof Equipment
     */
    readonly siteName: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    pipeline?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    groupName?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    material?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    fuel?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    equipmentClass?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    equipmentSubClass?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    manufacturerMake?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    manufacturerModel?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    manufacturerSerialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    operatorStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof Equipment
     */
    dateOfInstallation?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Equipment
     */
    dateOfLastModification?: Date;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    operatorUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    otherOperatorIds?: string;
    /**
     * 
     * @type {EquipmentTypeEnum}
     * @memberof Equipment
     */
    equipmentType: EquipmentTypeEnum;
    /**
     * 
     * @type {DataPointMapLocation}
     * @memberof Equipment
     */
    location: DataPointMapLocation;
    /**
     * 
     * @type {EquipmentGeometry}
     * @memberof Equipment
     */
    geometry: EquipmentGeometry;
    /**
     * 
     * @type {Date}
     * @memberof Equipment
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Equipment
     */
    endDate?: Date;
    /**
     * 
     * @type {any}
     * @memberof Equipment
     */
    extraData?: any;
}

/**
 * Check if a given object implements the Equipment interface.
 */
export function instanceOfEquipment(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('siteName' in value)) return false;
    if (!('equipmentType' in value)) return false;
    if (!('location' in value)) return false;
    if (!('geometry' in value)) return false;
    return true;
}

export function EquipmentFromJSON(json: any): Equipment {
    return EquipmentFromJSONTyped(json, false);
}

export function EquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Equipment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'site': json['site'] == null ? undefined : json['site'],
        'siteName': json['site_name'],
        'pipeline': json['pipeline'] == null ? undefined : json['pipeline'],
        'parent': json['parent'] == null ? undefined : json['parent'],
        'groupName': json['group_name'] == null ? undefined : json['group_name'],
        'material': json['material'] == null ? undefined : json['material'],
        'fuel': json['fuel'] == null ? undefined : json['fuel'],
        'equipmentClass': json['equipment_class'] == null ? undefined : json['equipment_class'],
        'equipmentSubClass': json['equipment_sub_class'] == null ? undefined : json['equipment_sub_class'],
        'manufacturerMake': json['manufacturer_make'] == null ? undefined : json['manufacturer_make'],
        'manufacturerModel': json['manufacturer_model'] == null ? undefined : json['manufacturer_model'],
        'manufacturerSerialNumber': json['manufacturer_serial_number'] == null ? undefined : json['manufacturer_serial_number'],
        'operatorStatus': json['operator_status'] == null ? undefined : json['operator_status'],
        'dateOfInstallation': json['date_of_installation'] == null ? undefined : (new Date(json['date_of_installation'])),
        'dateOfLastModification': json['date_of_last_modification'] == null ? undefined : (new Date(json['date_of_last_modification'])),
        'operatorUniqueId': json['operator_unique_id'] == null ? undefined : json['operator_unique_id'],
        'otherOperatorIds': json['other_operator_ids'] == null ? undefined : json['other_operator_ids'],
        'equipmentType': EquipmentTypeEnumFromJSON(json['equipment_type']),
        'location': DataPointMapLocationFromJSON(json['location']),
        'geometry': EquipmentGeometryFromJSON(json['geometry']),
        'startDate': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'endDate': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
    };
}

export function EquipmentToJSON(value?: Omit<Equipment, 'id'|'site_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'site': value['site'],
        'pipeline': value['pipeline'],
        'parent': value['parent'],
        'group_name': value['groupName'],
        'material': value['material'],
        'fuel': value['fuel'],
        'equipment_class': value['equipmentClass'],
        'equipment_sub_class': value['equipmentSubClass'],
        'manufacturer_make': value['manufacturerMake'],
        'manufacturer_model': value['manufacturerModel'],
        'manufacturer_serial_number': value['manufacturerSerialNumber'],
        'operator_status': value['operatorStatus'],
        'date_of_installation': value['dateOfInstallation'] == null ? undefined : ((value['dateOfInstallation'] as any).toISOString().substring(0,10)),
        'date_of_last_modification': value['dateOfLastModification'] == null ? undefined : ((value['dateOfLastModification'] as any).toISOString().substring(0,10)),
        'operator_unique_id': value['operatorUniqueId'],
        'other_operator_ids': value['otherOperatorIds'],
        'equipment_type': EquipmentTypeEnumToJSON(value['equipmentType']),
        'location': DataPointMapLocationToJSON(value['location']),
        'geometry': EquipmentGeometryToJSON(value['geometry']),
        'start_date': value['startDate'] == null ? undefined : ((value['startDate'] as any).toISOString().substring(0,10)),
        'end_date': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString().substring(0,10)),
        'extra_data': value['extraData'],
    };
}

