import {
    CSSProperties,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import * as d3 from "d3";
import "d3-transition";
import { EmissionRecordView, NonDetect } from "../../../apiClient/generated";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { RateWithUncertaintyCell } from "../../Emissions/Extras";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowsRotate,
    faArrowUp,
    faArrowUpArrowDown,
    faArrowUpRightFromSquare,
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { DateTime } from "luxon";
import { ROUTES } from "../../../routes";
import { EMISSION_COLORS } from "../../MapV2/constants";
import { useQuery } from "@tanstack/react-query";
import {
    useAppSelector,
    useEmissionRecordsApiClient,
    useNondetectsApiClient,
} from "../../../hooks";
import { SecondaryButton } from "../../../ui/Buttons";
import { TableFooter } from "../../DataTable/DataTableV3";
import { faSort } from "@fortawesome/pro-light-svg-icons";
import { faSort as faSortSolid } from "@fortawesome/pro-solid-svg-icons";

const dsMap = {
    THIRD_PARTY: "thirdParty",
    SELF_REPORTED: "operatorProvided",
    EPA: "epa",
};

interface EmissionHoverProps {
    emissionId: string;
    style?: CSSProperties;
}

export const EmissionHover = (props: EmissionHoverProps) => {
    const apiClient = useEmissionRecordsApiClient();
    const emissionQuery = useQuery({
        queryKey: ["emissionRecord", props.emissionId],
        queryFn: async () => {
            const response = await apiClient.emissionRecordsRetrieve({
                id: props.emissionId,
            });
            return response;
        },
        enabled: !!props.emissionId,
        refetchOnWindowFocus: false,
        retry: false,
    });

    if (emissionQuery.error) {
        return <noscript />;
    }

    return (
        <div
            className="px-2 py-3 z-50 bg-black bg-opacity-80 rounded text-white w-fit whitespace-nowrap"
            style={props.style}
        >
            {emissionQuery.data && (
                <div>
                    <p className="font-bold mb-2">
                        {emissionQuery.data.providerName}
                    </p>
                    <p className="mb-1">
                        {DateTime.fromJSDate(
                            emissionQuery.data.dataPoint.detectionTimestamp,
                        )
                            .setZone("utc")
                            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                    </p>
                    {emissionQuery.data.dataPoint.secondaryDataSource && (
                        <p className="mb-1">
                            {emissionQuery.data.dataPoint.secondaryDataSource}
                        </p>
                    )}
                    {emissionQuery.data.dataPoint.detectedRate && (
                        <p className="mb-1">
                            {(
                                emissionQuery.data.dataPoint.detectedRate / 1000
                            ).toFixed(2)}
                            {" ± "}
                            {(
                                (emissionQuery.data.dataPoint
                                    .detectedRateUncertainty ?? 0) / 1000
                            ).toFixed(2)}
                            {" kg/h"}
                        </p>
                    )}
                    {emissionQuery.data.dataPoint.concentration && (
                        <p className="mb-1">
                            {emissionQuery.data.dataPoint.concentration.toFixed(
                                2,
                            )}
                            {" ± "}
                            {emissionQuery.data.dataPoint.concentrationUncertainty?.toFixed(
                                2,
                            )}
                            {" ppm*m"}
                        </p>
                    )}
                </div>
            )}
            {emissionQuery.isLoading && (
                <div className="w-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faArrowsRotate} className="w-4" />
                </div>
            )}
        </div>
    );
};

interface NonDetectHoverProps {
    nonDetectId: string;
    style?: CSSProperties;
}

export const NonDetectHover = (props: NonDetectHoverProps) => {
    const apiClient = useNondetectsApiClient();
    const nonDetectQuery = useQuery({
        queryKey: ["nondetect", props.nonDetectId],
        queryFn: async () => {
            const response = await apiClient.nondetectsNondectRetrieve({
                id: props.nonDetectId,
            });
            return response;
        },
        enabled: !!props.nonDetectId,
        refetchOnWindowFocus: false,
        retry: false,
    });

    if (nonDetectQuery.error) {
        return <noscript />;
    }

    return (
        <div
            className="px-2 py-3 z-50 bg-black bg-opacity-80 rounded text-white w-fit whitespace-nowrap"
            style={props.style}
        >
            {nonDetectQuery.data && (
                <div>
                    <p className="font-bold mb-2">
                        {nonDetectQuery.data.scene.dataProvider.name}
                    </p>
                    <p className="font-bold mb-1">(Non Detect Observation)</p>
                    <p className="mb-1">
                        {DateTime.fromJSDate(nonDetectQuery.data.timestamp)
                            .setZone("utc")
                            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                    </p>
                    {nonDetectQuery.data.scene.secondaryDataSource && (
                        <p className="mb-1">
                            {nonDetectQuery.data.scene.secondaryDataSource}
                        </p>
                    )}
                    {nonDetectQuery.data.scene.detectionLimit !== null && (
                        <p className="mb-1">
                            {(
                                nonDetectQuery.data.scene.detectionLimit / 1000
                            ).toFixed(2)}
                            {" kg/h"}
                        </p>
                    )}
                </div>
            )}
            {nonDetectQuery.isLoading && (
                <div className="w-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faArrowsRotate} className="w-4" />
                </div>
            )}
        </div>
    );
};

interface EmissionPlotProps {
    data: EmissionRecordView[];
    nonDetects: NonDetect[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        provider?: Array<string>;
    };
    logScale: boolean;
    fitData: boolean;
    groupEmissions: boolean;
}

interface GroupedRecord {
    date: string;
    records: EmissionRecordView[];
    max: number;
}

interface SiteTotal {
    siteName: string;
    date: string;
    total: number;
    records: EmissionRecordView[];
}

export const EmissionPlot = ({
    data,
    nonDetects,
    hoverContext,
    setHoverContext,
    selectedContext,
    setSelectedContext,
    filters,
    fitData,
    logScale,
    groupEmissions,
}: EmissionPlotProps) => {
    const svgRef = useRef();
    const containerRef = useRef<HTMLDivElement>();
    const [cursorPosition, setCursorPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const userFlags = useAppSelector((s) => s.auth.flags);

    // FIXME: While we don't support multi-emission selection, use local state
    const [hoverGroup, setHoverGroup] = useState<SiteTotal>();

    // Function to update dimensions
    const updateDimensions = useCallback(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientHeight,
            });
        }
    }, []);

    // FIXME: Bridger specific code
    const [emissions, groupedSiteTotals] = useMemo((): [
        EmissionRecordView[],
        Record<string, SiteTotal>,
    ] => {
        if (!groupEmissions) {
            return [data, {}];
        }

        // Else, group bridger emissions, but leave other points as-is
        const newData = [];
        const toGroup = [];

        // Separate points into group/non-group
        data.forEach((i) => {
            if (
                i.dataPoint.data?.bridger_site_name &&
                i.dataPoint.data?.bridger_equipment_id
            ) {
                toGroup.push(i);
            } else {
                newData.push(i);
            }
        });

        // Group by site, equipment, and day to get maximum values
        const groupedBySiteAndName: Record<string, GroupedRecord> =
            toGroup.reduce((acc, record) => {
                const date = record.dataPoint.detectionTimestamp
                    .toISOString()
                    .split("T")[0];
                const key = `${
                    record.dataPoint.data!.bridger_site_name
                }_${date}_${record.dataPoint.data!.bridger_equipment_id}`;

                // If key doesn't exist, initialize it.
                if (!acc[key]) {
                    acc[key] = {
                        date,
                        records: [record],
                        max: record.dataPoint.detectedRate,
                    };
                } else {
                    // Else, add record to list and update max value if required.
                    acc[key].records.push(record);
                    if (acc[key].max < record.dataPoint.detectedRate) {
                        acc[key].max = record.dataPoint.detectedRate;
                    }
                }

                return acc;
            }, {});

        // Compute daily site totals
        const groupedSiteTotals: Record<string, SiteTotal> = Object.entries(
            groupedBySiteAndName,
        ).reduce((acc, [key, value]) => {
            const [siteName, date] = key.split("_");
            const siteKey = `${siteName}_${date}`;

            if (!acc[siteKey]) {
                acc[siteKey] = {
                    siteName,
                    date,
                    total: 0,
                    records: [],
                };
            }

            acc[siteKey].total += value.max;
            acc[siteKey].records.push(...value.records);

            return acc;
        }, {});

        return [newData, groupedSiteTotals];
    }, [groupEmissions, data]);

    // Effect for handling resize
    useEffect(() => {
        // Initial dimensions
        updateDimensions();

        // Add event listener for window resize
        window.addEventListener("resize", updateDimensions);

        // Create ResizeObserver for component-specific resizes
        const resizeObserver = new ResizeObserver(updateDimensions);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateDimensions);
            resizeObserver.disconnect();
        };
    }, [updateDimensions]);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const container = d3.select(containerRef.current);
        const width = container.node().clientWidth;
        const height = container.node().clientHeight;
        const margin = { top: 20, right: 5, bottom: 40, left: 70 };

        // Clear previous content
        svg.selectAll("*").remove();

        // Set up scales with padding
        let dateRange = d3.extent(data, (d) => d.dataPoint.detectionTimestamp);
        if (
            filters.detectionDateRangeAfter &&
            filters.detectionDateRangeBefore &&
            !fitData
        ) {
            dateRange = [
                new Date(filters.detectionDateRangeAfter),
                new Date(filters.detectionDateRangeBefore),
            ];
        }

        const xScale = d3
            .scaleTime()
            .domain(dateRange)
            .range([margin.left, width - margin.right])
            .nice();

        const yScaleFn = (useLogScale = true) => {
            const scale = useLogScale ? d3.scaleLog() : d3.scaleLinear();

            // Filter valid data points
            const validData = data.filter((d) => !!d.dataPoint.detectedRate);

            // Calculate min value considering uncertainty
            let minValue = d3.min(validData, (d) => {
                const uncertainty = d.dataPoint.detectedRateUncertainty || 0;
                return (d.dataPoint.detectedRate - uncertainty) / 1000;
            });
            // For non-detects force a minimum value of 0
            if (userFlags.includes("enable_site_page_non_detects")) {
                minValue = 0;
            }

            // Consider grouped emissions
            if (groupEmissions) {
                const groupedMinValue = d3.min(
                    Object.values(groupedSiteTotals),
                    (d) => {
                        return d.total / 1000;
                    },
                );
                if (minValue < groupedMinValue) {
                    minValue = groupedMinValue;
                }
            }

            // For log scale, ensure minimum value is at least 1
            if (useLogScale) {
                minValue = Math.max(minValue, 0.001);
            }

            // Calculate max value considering uncertainty
            let maxValue = d3.max(validData, (d) => {
                const uncertainty = d.dataPoint.detectedRateUncertainty || 0;
                return (d.dataPoint.detectedRate + uncertainty) / 1000;
            });

            // Consider grouped emissions
            if (groupEmissions) {
                const groupedMaxValue = d3.max(
                    Object.values(groupedSiteTotals),
                    (d) => {
                        return d.total / 1000;
                    },
                );
                if (maxValue < groupedMaxValue) {
                    maxValue = groupedMaxValue;
                }
            }

            if (userFlags.includes("enable_site_page_non_detects")) {
                if (maxValue === undefined) {
                    maxValue = 10000;
                }
            }

            return scale
                .domain([minValue, maxValue])
                .nice()
                .range([height - margin.bottom, margin.top]);
        };

        const yScaleDetection = yScaleFn(logScale);

        const computeXTicks = (numberOfTicks) => {
            const [start, end] = xScale.domain();
            const interval =
                (end.getTime() - start.getTime()) / (numberOfTicks - 1);
            return Array.from(
                { length: numberOfTicks },
                (_, i) => new Date(start.getTime() + interval * i),
            );
        };

        const computeYTicks = (numberOfTicks: number) => {
            const [start, end] = yScaleDetection.domain();
            if (logScale) {
                const interval = 10;
                const length = Math.log10(end) - Math.log10(start) + 1;
                return Array.from(
                    { length },
                    (_, i) => start * Math.pow(interval, i),
                );
            } else {
                const interval = (end - start) / (numberOfTicks - 1);
                return Array.from(
                    { length: numberOfTicks },
                    (_, i) => start + interval * i,
                );
            }
        };

        const xAxis = d3
            .axisBottom(xScale)
            .tickSize(3)
            .tickPadding(15)
            .tickFormat((d) => {
                const timeSpanDays =
                    (dateRange[1].getTime() - dateRange[0].getTime()) /
                    (1000 * 60 * 60 * 24);
                if (timeSpanDays < 7) {
                    return d3.timeFormat("%b %d, %H:%M")(d as any);
                } else {
                    return d3.timeFormat("%b %d, %Y")(d as any);
                }
            })
            .tickValues(computeXTicks(width / 120));

        let yAxisDetection;
        if (userFlags.includes("enable_site_page_non_detects")) {
            yAxisDetection = d3
                .axisLeft(yScaleDetection)
                .tickSizeOuter(0)
                .tickPadding(10)
                .tickFormat((d) => d3.format(",")(d))
                .tickValues(computeYTicks(6));
        } else {
            yAxisDetection = d3
                .axisLeft(yScaleDetection)
                .ticks(5, ",")
                .tickSizeOuter(0)
                .tickPadding(10)
                .tickFormat((d) => d3.format(",")(d));
        }

        // Add a background rect to handle clicks outside of points/lines
        svg.append("rect")
            .attr("x", margin.left)
            .attr("y", margin.top)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height - margin.top - margin.bottom)
            .attr("fill", "transparent")
            .on("click", () => {
                setSelectedContext();
            });

        // Add grid lines for x-axis
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .tickValues(computeXTicks(width / 120))
                    .tickSize(-height + margin.top + margin.bottom)
                    .tickFormat(() => ""),
            )
            .call((g) => g.select(".domain").remove())
            .selectAll("line")
            .style("stroke", "#e5e7eb")
            .style("stroke-dasharray", "3,3");

        let yGrid;
        if (userFlags.includes("enable_site_page_non_detects")) {
            yGrid = d3
                .axisLeft(yScaleDetection)
                .tickSize(-width + margin.left + margin.right)
                .tickFormat(() => "")
                .tickValues(computeYTicks(6));
        } else {
            yGrid = d3
                .axisLeft(yScaleDetection)
                .ticks(5, ",")
                .tickSize(-width + margin.left + margin.right)
                .tickFormat(() => "");
        }

        // Add grid lines for y-axis
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(${margin.left},0)`)
            .call(yGrid)
            .call((g) => g.select(".domain").remove())
            .selectAll("line")
            .style("stroke", "#e5e7eb")
            .style("stroke-width", "1.5")
            .style("stroke-dasharray", "5,5");

        // Append x-axis and style it
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .classed("text-gray-500", true)
            .call(xAxis)
            .selectAll("text")
            .style("font-size", "14px");

        // Append y-axis and style it
        svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .classed("text-gray-500", true)
            .call(yAxisDetection)
            .selectAll("text")
            .style("font-size", "14px");

        // Add y-axis label at the bottom
        svg.append("text")
            .classed("text-gray-500", true)
            .attr("x", margin.left)
            .attr("y", margin.top - 10)
            .attr("text-anchor", "middle")
            .style("font-size", "14px")
            .text("kg/hr");

        // Add error bars and points as before
        svg.append("g")
            .attr("class", "error-bar")
            .selectAll("line")
            .data(emissions)
            .enter()
            .filter((d) => d.dataPoint.detectedRate !== undefined)
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("y1", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate +
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("y2", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate -
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .style("opacity", (d) => {
                if (selectedContext) {
                    return d.id == selectedContext ? 1 : 0;
                }
                if (hoverContext) {
                    return d.id == hoverContext ? 1 : 0;
                }
                return 0;
            });

        // Add horizontal caps to the error bars (T-shape)
        svg.append("g")
            .attr("class", "error-bar")
            .selectAll("line")
            .data(emissions)
            .enter()
            .filter(
                (d) =>
                    d.dataPoint.detectedRate !== undefined &&
                    d.dataPoint.detectedRateUncertainty !== undefined,
            )
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp) - 5)
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp) + 5)
            .attr("y1", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate +
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("y2", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate +
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .style("opacity", (d) => {
                if (selectedContext) {
                    return d.id == selectedContext ? 1 : 0;
                }
                if (hoverContext) {
                    return d.id == hoverContext ? 1 : 0;
                }
                return 0;
            });

        svg.append("g")
            .attr("class", "error-bar")
            .selectAll("line")
            .data(emissions)
            .enter()
            .filter(
                (d) =>
                    d.dataPoint.detectedRate !== undefined &&
                    d.dataPoint.detectedRateUncertainty !== undefined,
            )
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp) - 5)
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp) + 5)
            .attr("y1", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate -
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("y2", (d) =>
                yScaleDetection(
                    (d.dataPoint.detectedRate -
                        d.dataPoint.detectedRateUncertainty) /
                        1000,
                ),
            )
            .attr("stroke", "black")
            .attr("stroke-width", 1)
            .style("opacity", (d) => {
                if (selectedContext) {
                    return d.id == selectedContext ? 1 : 0;
                }
                if (hoverContext) {
                    return d.id == hoverContext ? 1 : 0;
                }
                return 0;
            });

        // First, define a pattern for elements without infrastructure
        svg.append("defs")
            .selectAll("pattern")
            .data(Object.entries(EMISSION_COLORS))
            .enter()
            .append("pattern")
            .attr("id", ([key]) => `noInfrastructurePattern-${key}`)
            .attr("patternUnits", "userSpaceOnUse")
            .attr("width", 4)
            .attr("height", 4)
            .each(function ([, color]) {
                // Add white background rectangle
                d3.select(this)
                    .append("rect")
                    .attr("width", 4)
                    .attr("height", 4)
                    .attr("fill", "white");

                // Add the diagonal lines pattern
                d3.select(this)
                    .append("path")
                    .attr("d", "M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2")
                    .attr("stroke", `rgb(${color.join(",")})`)
                    .attr("stroke-width", 1);
            });

        // Create a group for both the visible line and hover area
        const concentrationLines = svg
            .append("g")
            .selectAll("g")
            .data(emissions)
            .enter()
            .filter(
                (d) =>
                    d.dataPoint.concentration !== undefined &&
                    !d.dataPoint.detectedRate,
            )
            .append("g");

        const nonDetectLines = svg
            .append("g")
            .selectAll("g")
            .data(nonDetects)
            .enter()
            .append("g");

        nonDetectLines
            .append("line")
            .attr("x1", (d) => xScale(d.timestamp))
            .attr("x2", (d) => xScale(d.timestamp))
            .attr("y1", (d) => {
                const end = yScaleDetection.domain()[1];
                const detectionLimit = d.scene.detectionLimit / 1000;
                return detectionLimit > end
                    ? yScaleDetection(end - 100)
                    : margin.top;
            })
            .attr("y2", (d) => {
                const end = yScaleDetection.domain()[1];
                const detectionLimit = d.scene.detectionLimit / 1000;
                return yScaleDetection(
                    detectionLimit > end ? end + 100 : detectionLimit,
                );
            })
            .attr("stroke", () => {
                return "#4096FF";
            })
            .attr("stroke-width", () => {
                return 7;
            })
            .style("opacity", 0.3)
            .on("mouseover", (event, d) => {
                setHoverContext(d.id);
                const containerRect =
                    containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: event.clientX - containerRect.left,
                    y: event.clientY - containerRect.top,
                });
            })
            .on("mouseout", () => {
                setHoverContext();
                setCursorPosition(null);
            });

        // Add the visible dashed line
        concentrationLines
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("y1", margin.top)
            .attr("y2", height - margin.bottom)
            .attr("stroke", (d) => {
                const color = EMISSION_COLORS[dsMap[d.dataPoint.dataSource]];
                return color ? `rgb(${color.join(",")})` : "#000";
            })
            .attr("stroke-width", (d) => {
                if (selectedContext) {
                    return selectedContext === d.id ? 2.5 : 1.5;
                }
                if (hoverContext) {
                    return hoverContext === d.id ? 2.5 : 1.5;
                }
                return 1;
            })
            .attr("stroke-dasharray", (d) => (d.site ? "6,6" : "4,4"))
            .style("opacity", 0.5);

        // Add the invisible wider line for easier hovering
        concentrationLines
            .append("line")
            .attr("x1", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("x2", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("y1", margin.top)
            .attr("y2", height - margin.bottom)
            .style("cursor", "pointer")
            .attr("stroke", (d) => {
                if (selectedContext) {
                    return selectedContext === d.id
                        ? "#00000010"
                        : "transparent";
                }
                if (hoverContext) {
                    return hoverContext === d.id ? "#00000010" : "transparent";
                }
                return "transparent";
            })
            .attr("stroke-width", 10)
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                setHoverContext(d.id);
                const containerRect =
                    containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: event.clientX - containerRect.left,
                    y: event.clientY - containerRect.top,
                });
            })
            .on("mouseout", () => {
                setHoverContext();
                setCursorPosition(null);
            })
            .on("click", (event, d) => {
                // Prevent background click from triggering
                event.stopPropagation();
                setSelectedContext(selectedContext === d.id ? undefined : d.id);
            });

        // Add site total points
        svg.append("g")
            .selectAll("circle")
            .data(Object.values(groupedSiteTotals))
            .join("circle")
            .attr("cx", (d) => xScale(new Date(d.date)))
            .attr("cy", (d) => yScaleDetection(d.total / 1000))
            .attr("r", 5)
            .attr("stroke", "gray")
            .attr("stroke-width", 2)
            .attr("stroke-dasharray", "3,3")
            .attr("fill", (d) => {
                const dataSource = dsMap[d.records[0].dataPoint.dataSource];
                const color = EMISSION_COLORS[dataSource];
                return color ? `rgba(${color.join(",")}, 0.7)` : "#000";
            })
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                setHoverGroup(d);
                d3.select(event.currentTarget)
                    .attr("r", 7)
                    .attr("stroke", "#1677ff");
            })
            .on("mouseout", (event) => {
                setHoverGroup(undefined);
                d3.select(event.currentTarget)
                    .attr("r", 5)
                    .attr("stroke", "gray");
            })
            .on("click", (event, d) => {
                event.stopPropagation();
                console.log("Site total clicked:", d);
            });

        // Add scatterplot points as rectangles
        svg.append("g")
            .selectAll("circle")
            .data(emissions)
            .enter()
            .filter((d) => d.dataPoint.detectedRate !== undefined)
            .append("circle")
            .style("cursor", "pointer")
            .attr("cx", (d) => xScale(d.dataPoint.detectionTimestamp))
            .attr("cy", (d) => yScaleDetection(d.dataPoint.detectedRate / 1000))
            .attr("r", (d) =>
                selectedContext === d.id ||
                (!selectedContext && hoverContext === d.id)
                    ? 7
                    : 5,
            )
            .attr("fill", (d) => {
                const dataSource = dsMap[d.dataPoint.dataSource];
                if (!d.site) {
                    return `url(#noInfrastructurePattern-${dataSource})`;
                }
                const color = EMISSION_COLORS[dataSource];
                return color ? `rgb(${color.join(",")})` : "#000";
            })
            .attr("stroke", (d) => {
                if (
                    selectedContext === d.id ||
                    (!selectedContext && hoverContext === d.id)
                ) {
                    return "#1677ff";
                }
                if (!d.site) {
                    const color =
                        EMISSION_COLORS[dsMap[d.dataPoint.dataSource]];
                    return color ? `rgb(${color.join(",")})` : "#000";
                }
                return "transparent";
            })
            .attr("stroke-width", (d) => {
                if (
                    selectedContext === d.id ||
                    (!selectedContext && hoverContext === d.id)
                ) {
                    return 2;
                }
                return d.site ? 0 : 1;
            })
            .on("mouseover", (event, d) => {
                setHoverContext(d.id);
                const containerRect =
                    containerRef.current.getBoundingClientRect();
                setCursorPosition({
                    x: event.clientX - containerRect.left,
                    y: event.clientY - containerRect.top,
                });
            })
            .on("mouseout", () => {
                setHoverContext();
                setCursorPosition(null);
            })
            .on("click", (event, d) => {
                event.stopPropagation();
                setSelectedContext(selectedContext === d.id ? undefined : d.id);
            });
    }, [
        emissions,
        nonDetects,
        userFlags,
        groupedSiteTotals,
        hoverContext,
        setHoverContext,
        selectedContext,
        setSelectedContext,
        filters,
        logScale,
        fitData,
        dimensions,
        data,
        groupEmissions,
    ]);

    return (
        <div className="px-4">
            <div
                ref={containerRef}
                className="w-full h-[400px] relative"
                onMouseMove={(e) =>
                    setCursorPosition({ x: e.clientX, y: e.clientY })
                }
                onMouseLeave={() => setCursorPosition(null)}
            >
                {hoverContext && cursorPosition && (
                    <EmissionHover
                        emissionId={hoverContext}
                        style={{
                            position: "fixed",
                            left: cursorPosition.x + 100,
                            top: cursorPosition.y + 15,
                            pointerEvents: "none",
                            transform: "translate(-50%, 0)",
                        }}
                    />
                )}
                {hoverContext && cursorPosition && (
                    <NonDetectHover
                        nonDetectId={hoverContext}
                        style={{
                            position: "fixed",
                            left: cursorPosition.x + 100,
                            top: cursorPosition.y + 15,
                            pointerEvents: "none",
                            transform: "translate(-50%, 0)",
                        }}
                    />
                )}
                {hoverGroup && cursorPosition && (
                    <div
                        className="px-2 py-3 z-50 bg-black bg-opacity-80 rounded text-white w-fit whitespace-nowrap"
                        style={{
                            position: "fixed",
                            left: cursorPosition.x + 100,
                            top: cursorPosition.y + 15,
                            pointerEvents: "none",
                            transform: "translate(-50%, 0)",
                        }}
                    >
                        <p className="font-bold mb-2">
                            {hoverGroup.records.length} emissions (grouped)
                        </p>

                        <p className="mb-1">
                            {"Date: "}
                            {DateTime.fromISO(hoverGroup.date)
                                .setZone("utc")
                                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                        </p>
                        <p className="mb-1">
                            {"Total rate: "}
                            {(hoverGroup.total / 1000).toFixed(2)}
                            {" kg/h"}
                        </p>

                        <p>Ungroup emissions to highlight plumes in map.</p>
                    </div>
                )}
                <svg
                    ref={svgRef}
                    style={{ width: "100%", height: "100%" }}
                ></svg>
            </div>
            <div className="h-full flex justify-center text-sm text-neutral-500">
                <div className="rounded px-4 py-2 flex items-center justify-center gap-4 mb-2 border bg-neutral-50/2 border-neutral-200">
                    <div className="flex items-center gap-2">
                        <div
                            className="h-3 w-3 rounded-full flex-shrink-0"
                            style={{
                                backgroundColor: `rgb(${EMISSION_COLORS.epa})`,
                            }}
                        />
                        EPA Super Emitter Program
                    </div>
                    <div className="flex items-center gap-2">
                        <div
                            className="h-3 w-3 rounded-full flex-shrink-0"
                            style={{
                                backgroundColor: `rgb(${EMISSION_COLORS.operatorProvided})`,
                            }}
                        />
                        Company Monitoring Data
                    </div>
                    <div className="flex items-center gap-2">
                        <div
                            className="h-3 w-3 rounded-full flex-shrink-0"
                            style={{
                                backgroundColor: `rgb(${EMISSION_COLORS.thirdParty})`,
                            }}
                        />
                        3rd Party Detections
                    </div>
                    <div className="flex items-center gap-2">
                        <svg
                            className="h-3 w-3 flex-shrink-0"
                            viewBox="0 0 12 12"
                        >
                            <defs>
                                <pattern
                                    id="stripePattern-thirdParty"
                                    patternUnits="userSpaceOnUse"
                                    width="4"
                                    height="4"
                                >
                                    <rect width="4" height="4" fill="white" />
                                    <path
                                        d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                                        stroke="gray"
                                        strokeWidth="1"
                                    />
                                </pattern>
                            </defs>
                            <circle
                                cx="6"
                                cy="6"
                                r="5"
                                fill={`url(#stripePattern-thirdParty)`}
                                stroke="gray"
                                strokeWidth="1"
                            />
                        </svg>
                        Pending site confirmation
                    </div>

                    <div className="flex items-center gap-2">
                        <svg
                            className="h-3 w-3 flex-shrink-0"
                            viewBox="0 0 12 12"
                        >
                            <circle
                                cx="6"
                                cy="6"
                                r="5"
                                stroke="gray"
                                strokeWidth="2"
                                strokeDasharray="3,3"
                                fill="white"
                            />
                        </svg>
                        Grouped emissions
                    </div>
                </div>
            </div>
        </div>
    );
};

interface EmissionsTableProps {
    emissions: EmissionRecordView[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
}

const columnHelper = createColumnHelper<EmissionRecordView>();
const columns = [
    columnHelper.accessor("dataPoint.detectionTimestamp", {
        id: "detectionTimestamp",
        cell: ({ getValue }) =>
            DateTime.fromJSDate(getValue())
                .setZone("utc")
                .toLocaleString(DateTime.DATETIME_MED),
        header: "Detected at (UTC)",
        sortingFn: (rowA, rowB) => {
            return (
                rowA.original.dataPoint.detectionTimestamp.getTime() -
                rowB.original.dataPoint.detectionTimestamp.getTime()
            );
        },
    }),
    columnHelper.accessor(
        (row) => ({
            providerName: row.providerName,
            secondaryDataSource: row.dataPoint?.secondaryDataSource,
        }),
        {
            id: "provider",
            cell: (info) => {
                const value = info.getValue();
                return `${value.providerName} ${
                    value.secondaryDataSource?.trim() !== ""
                        ? "(" + value.secondaryDataSource + ")"
                        : ""
                }`;
            },
            header: () => "Provider",
            enableSorting: false,
        },
    ),
    columnHelper.accessor(
        (row) => {
            return {
                detectedRate: row.dataPoint?.detectedRate,
                detectedRateUncertainty: row.dataPoint?.detectedRateUncertainty,
            };
        },
        {
            id: "detectedRate",
            header: "Detected Rate",
            cell: (info) => {
                const { detectedRate, detectedRateUncertainty } =
                    info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={detectedRate ? detectedRate / 1000 : detectedRate}
                        uncertainty={
                            detectedRateUncertainty
                                ? detectedRateUncertainty / 1000
                                : detectedRateUncertainty
                        }
                        unit="kg/h"
                    />
                );
            },
            sortingFn: (rowA, rowB) => {
                return (
                    rowA.original.dataPoint.detectedRate -
                    rowB.original.dataPoint.detectedRate
                );
            },
        },
    ),
    columnHelper.accessor(
        (row) => {
            return {
                concentration: row.dataPoint?.concentration,
                concentrationUncertainty:
                    row.dataPoint?.concentrationUncertainty,
            };
        },
        {
            id: "concentration",
            header: "Concentration",
            cell: (info) => {
                const { concentration, concentrationUncertainty } =
                    info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={concentration}
                        uncertainty={concentrationUncertainty}
                        unit="ppm*m"
                    />
                );
            },
            sortingFn: (rowA, rowB) => {
                return (
                    rowA.original.dataPoint.concentration -
                    rowB.original.dataPoint.concentration
                );
            },
        },
    ),
    columnHelper.display({
        id: "actions",
        header: "",
        enableColumnFilter: false,
        cell: (props) => {
            return (
                <div className="flex items-center justify-end">
                    <SecondaryButton
                        onClick={() => {
                            const url =
                                props.row.original.dataPoint.dataSource ===
                                "SELF_REPORTED"
                                    ? ROUTES.DASHBOARD_SELF_REPORTED
                                    : ROUTES.DASHBOARD_THIRD_PARTY;
                            window.open(
                                `${url}?selectedEvent=${props.row.original.id}`,
                                "_blank",
                            );
                        }}
                        variant="sm"
                    >
                        Manage emission
                        <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className="w-3"
                        />
                    </SecondaryButton>
                </div>
            );
        },
    }),
];

export const EmissionsTable = (props: EmissionsTableProps) => {
    const table = useReactTable({
        data: props.emissions,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        autoResetPageIndex: false,
    });

    return (
        <div className="h-full flex flex-col text-sm whitespace-nowrap">
            <div className="absolute -mt-10 right-44">
                <SecondaryButton onClick={() => table.setSorting([])}>
                    Clear Sorting
                </SecondaryButton>
            </div>
            <div>
                <table className="w-full">
                    <thead className="bg-ae-gray-100 text-left text-sm font-bold text-ae-blue-900 border-b">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className="px-4 py-3 hover:bg-neutral-200 border-r border-gray-200 last:border-r-0"
                                    >
                                        <div className="flex items-center justify-between whitespace-nowrap">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                            <div className="ml-3 flex items-center gap-1">
                                                {header.column.getCanSort() && (
                                                    <FontAwesomeIcon
                                                        icon={
                                                            header.column.getIsSorted()
                                                                ? faSortSolid
                                                                : faSort
                                                        }
                                                        className={`w-3 ${header.column.getIsSorted() ? "" : "text-gray-400"}`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className={`h-10 px-4 ${
                                            props.selectedContext ===
                                            row.original.id
                                                ? "bg-gray-300"
                                                : props.hoverContext ===
                                                    row.original.id
                                                  ? "bg-gray-200"
                                                  : ""
                                        }`}
                                        onMouseEnter={() =>
                                            props.setHoverContext(
                                                row.original.id,
                                            )
                                        }
                                        onMouseLeave={() =>
                                            props.setHoverContext()
                                        }
                                        onClick={() => {
                                            props.setSelectedContext(
                                                props.selectedContext ===
                                                    row.original.id
                                                    ? undefined
                                                    : row.original.id,
                                            );
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <TableFooter
                loadingData={false}
                currentPage={table.getState().pagination.pageIndex + 1}
                pageCount={table.getPageCount()}
                itemCount={props.emissions.length}
                nextPage={() => table.nextPage()}
                nextPageEnabled={table.getCanNextPage()}
                previousPage={() => table.previousPage()}
                previousPageEnabled={table.getCanPreviousPage()}
                defaultPageSize={5}
                selectedPageSize={table.getState().pagination.pageSize}
                onSelectPageSize={(e) => {
                    table.setPageSize(Number(e.target.value));
                }}
                onPageChange={(p) => table.setPageIndex(p - 1)}
            />
        </div>
    );
};

const nonDetectsColumnHelper = createColumnHelper<NonDetect>();
const nonDetectsColumns = [
    nonDetectsColumnHelper.accessor("timestamp", {
        id: "timestamp",
        cell: ({ getValue }) =>
            DateTime.fromJSDate(getValue())
                .setZone("utc")
                .toLocaleString(DateTime.DATETIME_MED),
        header: "Timestamp (UTC)",
        sortingFn: (rowA, rowB) => {
            return (
                rowA.original.timestamp.getTime() -
                rowB.original.timestamp.getTime()
            );
        },
    }),
    nonDetectsColumnHelper.accessor(
        (row) => ({
            providerName: row.scene.dataProvider.name,
            secondaryDataSource: row.scene.secondaryDataSource,
        }),
        {
            id: "provider",
            cell: (info) => {
                const value = info.getValue();
                return `${value.providerName} ${
                    value.secondaryDataSource?.trim() !== ""
                        ? "(" + value.secondaryDataSource + ")"
                        : ""
                }`;
            },
            header: () => "Provider",
            enableSorting: false,
        },
    ),
    nonDetectsColumnHelper.accessor(
        (row) => {
            return {
                detectionLimit: row.scene.detectionLimit,
            };
        },
        {
            id: "detectionLimit",
            header: "Detection Limit",
            cell: (info) => {
                const { detectionLimit } = info.getValue();
                return (
                    <RateWithUncertaintyCell
                        rate={detectionLimit / 1000}
                        unit="kg/h"
                    />
                );
            },
            sortingFn: (rowA, rowB) => {
                return (
                    rowA.original.scene.detectionLimit -
                    rowB.original.scene.detectionLimit
                );
            },
        },
    ),
    nonDetectsColumnHelper.accessor(
        (row) => {
            return {
                id: row.id,
                sceneId: row.scene.id,
                externalSceneId: row.scene.sceneId,
            };
        },
        {
            id: "extraData",
            header: "Extra data (ADMINS)",
            cell: (info) => {
                const value = info.getValue();
                return (
                    <div>
                        NonDetectId: {value.id}
                        <br />
                        SceneId: {value.sceneId}
                        <br />
                        ExternalSceneId: {value.externalSceneId}
                    </div>
                );
            },
        },
    ),
];

interface NonDetectsTableProps {
    nonDetects: NonDetect[];
    hoverContext?: string;
    setHoverContext?: (nonDetectId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (nonDetectId?: string) => void;
}

export const NonDetectsTable = (props: NonDetectsTableProps) => {
    const isSuperuser = useAppSelector((state) => state.auth.isSuperuser);

    const columns = isSuperuser
        ? nonDetectsColumns
        : nonDetectsColumns.filter((column) => column.id !== "extraData");

    const table = useReactTable({
        data: props.nonDetects,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        autoResetPageIndex: false,
    });

    return (
        <div className="h-full flex flex-col text-sm whitespace-nowrap">
            <div className="absolute -mt-10 right-10">
                <SecondaryButton onClick={() => table.setSorting([])}>
                    Clear Sorting
                </SecondaryButton>
            </div>
            <div>
                <table className="w-full">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className="cursor-pointer px-4 text-left h-10 bg-gray-100"
                                    >
                                        <div className="flex items-center gap-2">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                            {header.column.getIsSorted() ? (
                                                header.column.getIsSorted() ===
                                                "asc" ? (
                                                    <FontAwesomeIcon
                                                        icon={faArrowUp}
                                                        className="w-4"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faArrowDown}
                                                        className="w-4"
                                                    />
                                                )
                                            ) : header.column.getCanSort() ? (
                                                <FontAwesomeIcon
                                                    icon={faArrowUpArrowDown}
                                                    className="w-4"
                                                />
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className={`h-10 px-4 ${
                                            props.selectedContext ===
                                            row.original.id.toString()
                                                ? "bg-gray-300"
                                                : props.hoverContext ===
                                                    row.original.id.toString()
                                                  ? "bg-gray-200"
                                                  : ""
                                        }`}
                                        onMouseEnter={() =>
                                            props.setHoverContext(
                                                row.original.id.toString(),
                                            )
                                        }
                                        onMouseLeave={() =>
                                            props.setHoverContext()
                                        }
                                        onClick={() => {
                                            props.setSelectedContext(
                                                props.selectedContext ===
                                                    row.original.id.toString()
                                                    ? undefined
                                                    : row.original.id.toString(),
                                            );
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="py-2 px-4 border-t flex items-center justify-between">
                <div>{props.nonDetects.length} items</div>
                <div className="flex items-center">
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className="px-2 py-1 mr-1 border border-transparent disabled:text-gray-400 disabled:border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550 rounded"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="w-4" />
                    </button>
                    <span className="mx-2">
                        Page{" "}
                        <strong>
                            {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount()}
                        </strong>
                    </span>
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className="px-2 py-1 mr-1 border border-transparent disabled:text-gray-400 disabled:border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550 rounded"
                    >
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="w-4"
                        />
                    </button>
                </div>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                    }}
                    className="ml-2 p-1 w-32 border rounded text-sm"
                >
                    {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};
