/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DataPointMapLocation } from './DataPointMapLocation';
import {
    DataPointMapLocationFromJSON,
    DataPointMapLocationFromJSONTyped,
    DataPointMapLocationToJSON,
} from './DataPointMapLocation';
import type { EquipmentGeometry } from './EquipmentGeometry';
import {
    EquipmentGeometryFromJSON,
    EquipmentGeometryFromJSONTyped,
    EquipmentGeometryToJSON,
} from './EquipmentGeometry';
import type { EquipmentTypeEnum } from './EquipmentTypeEnum';
import {
    EquipmentTypeEnumFromJSON,
    EquipmentTypeEnumFromJSONTyped,
    EquipmentTypeEnumToJSON,
} from './EquipmentTypeEnum';

/**
 * Serializer used by map that returns the least amount of
 * information necessary to render the map.
 * @export
 * @interface EquipmentMapList
 */
export interface EquipmentMapList {
    /**
     * 
     * @type {string}
     * @memberof EquipmentMapList
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentMapList
     */
    site?: string;
    /**
     * 
     * @type {DataPointMapLocation}
     * @memberof EquipmentMapList
     */
    location: DataPointMapLocation;
    /**
     * 
     * @type {EquipmentGeometry}
     * @memberof EquipmentMapList
     */
    geometry: EquipmentGeometry;
    /**
     * 
     * @type {EquipmentTypeEnum}
     * @memberof EquipmentMapList
     */
    equipmentType: EquipmentTypeEnum;
}

/**
 * Check if a given object implements the EquipmentMapList interface.
 */
export function instanceOfEquipmentMapList(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('location' in value)) return false;
    if (!('geometry' in value)) return false;
    if (!('equipmentType' in value)) return false;
    return true;
}

export function EquipmentMapListFromJSON(json: any): EquipmentMapList {
    return EquipmentMapListFromJSONTyped(json, false);
}

export function EquipmentMapListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentMapList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'site': json['site'] == null ? undefined : json['site'],
        'location': DataPointMapLocationFromJSON(json['location']),
        'geometry': EquipmentGeometryFromJSON(json['geometry']),
        'equipmentType': EquipmentTypeEnumFromJSON(json['equipment_type']),
    };
}

export function EquipmentMapListToJSON(value?: Omit<EquipmentMapList, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'site': value['site'],
        'location': DataPointMapLocationToJSON(value['location']),
        'geometry': EquipmentGeometryToJSON(value['geometry']),
        'equipment_type': EquipmentTypeEnumToJSON(value['equipmentType']),
    };
}

