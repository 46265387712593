/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminAuthenticationResponseSerializerCreate,
  AdminAuthenticationResponseSerializerGet,
  AdminViewAsCompanyRequest,
  LoginViewRequest,
  LoginViewResponse,
  PasswordReset,
  PasswordResetRequest,
  SSOEnabledResponse,
  SSORequiredCheckRequest,
  SSORequiredCheckResponse,
  SecondFactorViewRequest,
  SecondFactorViewResponse,
} from '../models/index';
import {
    AdminAuthenticationResponseSerializerCreateFromJSON,
    AdminAuthenticationResponseSerializerCreateToJSON,
    AdminAuthenticationResponseSerializerGetFromJSON,
    AdminAuthenticationResponseSerializerGetToJSON,
    AdminViewAsCompanyRequestFromJSON,
    AdminViewAsCompanyRequestToJSON,
    LoginViewRequestFromJSON,
    LoginViewRequestToJSON,
    LoginViewResponseFromJSON,
    LoginViewResponseToJSON,
    PasswordResetFromJSON,
    PasswordResetToJSON,
    PasswordResetRequestFromJSON,
    PasswordResetRequestToJSON,
    SSOEnabledResponseFromJSON,
    SSOEnabledResponseToJSON,
    SSORequiredCheckRequestFromJSON,
    SSORequiredCheckRequestToJSON,
    SSORequiredCheckResponseFromJSON,
    SSORequiredCheckResponseToJSON,
    SecondFactorViewRequestFromJSON,
    SecondFactorViewRequestToJSON,
    SecondFactorViewResponseFromJSON,
    SecondFactorViewResponseToJSON,
} from '../models/index';

export interface Auth2faCreateRequest {
    secondFactorViewRequest: SecondFactorViewRequest;
}

export interface AuthAdminViewAsCompanyCreateRequest {
    adminViewAsCompanyRequest: AdminViewAsCompanyRequest;
}

export interface AuthLoginCreateRequest {
    loginViewRequest: LoginViewRequest;
}

export interface AuthPasswordResetCreateRequest {
    passwordResetRequest?: PasswordResetRequest;
}

export interface AuthPasswordResetUpdateRequest {
    passwordResetRequest?: PasswordResetRequest;
}

export interface AuthSsoRequiredCreateRequest {
    sSORequiredCheckRequest: SSORequiredCheckRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Check if token is valid and returns frontend API key.
     */
    async auth2faCreateRaw(requestParameters: Auth2faCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecondFactorViewResponse>> {
        if (requestParameters['secondFactorViewRequest'] == null) {
            throw new runtime.RequiredError(
                'secondFactorViewRequest',
                'Required parameter "secondFactorViewRequest" was null or undefined when calling auth2faCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/2fa`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecondFactorViewRequestToJSON(requestParameters['secondFactorViewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecondFactorViewResponseFromJSON(jsonValue));
    }

    /**
     * Check if token is valid and returns frontend API key.
     */
    async auth2faCreate(requestParameters: Auth2faCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecondFactorViewResponse> {
        const response = await this.auth2faCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates session authentication from token authentication.  Check if user is authenticated and has staff/admin permissions.
     */
    async authAdminAuthCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminAuthenticationResponseSerializerCreate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/auth/admin-auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminAuthenticationResponseSerializerCreateFromJSON(jsonValue));
    }

    /**
     * Creates session authentication from token authentication.  Check if user is authenticated and has staff/admin permissions.
     */
    async authAdminAuthCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminAuthenticationResponseSerializerCreate> {
        const response = await this.authAdminAuthCreateRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check if user is authenticated and has staff/admin permissions.  Return a session key if user is authenticated.
     */
    async authAdminAuthRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminAuthenticationResponseSerializerGet>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/auth/admin-auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminAuthenticationResponseSerializerGetFromJSON(jsonValue));
    }

    /**
     * Check if user is authenticated and has staff/admin permissions.  Return a session key if user is authenticated.
     */
    async authAdminAuthRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminAuthenticationResponseSerializerGet> {
        const response = await this.authAdminAuthRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a new user that has view-only access to company data.  Only works if user is a superuser, and it only creates a temporary account.
     */
    async authAdminViewAsCompanyCreateRaw(requestParameters: AuthAdminViewAsCompanyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['adminViewAsCompanyRequest'] == null) {
            throw new runtime.RequiredError(
                'adminViewAsCompanyRequest',
                'Required parameter "adminViewAsCompanyRequest" was null or undefined when calling authAdminViewAsCompanyCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/auth/admin-view-as-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminViewAsCompanyRequestToJSON(requestParameters['adminViewAsCompanyRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new user that has view-only access to company data.  Only works if user is a superuser, and it only creates a temporary account.
     */
    async authAdminViewAsCompanyCreate(requestParameters: AuthAdminViewAsCompanyCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authAdminViewAsCompanyCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Check if user and email are correct and creates a token for 2nd step authentication.
     */
    async authLoginCreateRaw(requestParameters: AuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginViewResponse>> {
        if (requestParameters['loginViewRequest'] == null) {
            throw new runtime.RequiredError(
                'loginViewRequest',
                'Required parameter "loginViewRequest" was null or undefined when calling authLoginCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginViewRequestToJSON(requestParameters['loginViewRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginViewResponseFromJSON(jsonValue));
    }

    /**
     * Check if user and email are correct and creates a token for 2nd step authentication.
     */
    async authLoginCreate(requestParameters: AuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginViewResponse> {
        const response = await this.authLoginCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout user
     */
    async authLogoutCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout user
     */
    async authLogoutCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authLogoutCreateRaw(initOverrides);
    }

    /**
     * Endpoint to create a password reset request and trigger email to user.
     */
    async authPasswordResetCreateRaw(requestParameters: AuthPasswordResetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordReset>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/password-reset/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters['passwordResetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetFromJSON(jsonValue));
    }

    /**
     * Endpoint to create a password reset request and trigger email to user.
     */
    async authPasswordResetCreate(requestParameters: AuthPasswordResetCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordReset> {
        const response = await this.authPasswordResetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint to change user password after password reset.
     */
    async authPasswordResetUpdateRaw(requestParameters: AuthPasswordResetUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordReset>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/password-reset/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters['passwordResetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetFromJSON(jsonValue));
    }

    /**
     * Endpoint to change user password after password reset.
     */
    async authPasswordResetUpdate(requestParameters: AuthPasswordResetUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordReset> {
        const response = await this.authPasswordResetUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if user email requires SSO check.
     */
    async authSsoRequiredCreateRaw(requestParameters: AuthSsoRequiredCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SSORequiredCheckResponse>> {
        if (requestParameters['sSORequiredCheckRequest'] == null) {
            throw new runtime.RequiredError(
                'sSORequiredCheckRequest',
                'Required parameter "sSORequiredCheckRequest" was null or undefined when calling authSsoRequiredCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/auth/sso-required`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SSORequiredCheckRequestToJSON(requestParameters['sSORequiredCheckRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SSORequiredCheckResponseFromJSON(jsonValue));
    }

    /**
     * Check if user email requires SSO check.
     */
    async authSsoRequiredCreate(requestParameters: AuthSsoRequiredCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SSORequiredCheckResponse> {
        const response = await this.authSsoRequiredCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if SSO is enabled platform-wide.
     */
    async authSsoRequiredRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SSOEnabledResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/auth/sso-required`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SSOEnabledResponseFromJSON(jsonValue));
    }

    /**
     * Check if SSO is enabled platform-wide.
     */
    async authSsoRequiredRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SSOEnabledResponse> {
        const response = await this.authSsoRequiredRetrieveRaw(initOverrides);
        return await response.value();
    }

}
