/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Site } from './Site';
import {
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
} from './Site';

/**
 * 
 * @export
 * @interface PaginatedSiteList
 */
export interface PaginatedSiteList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSiteList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSiteList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSiteList
     */
    previous?: string;
    /**
     * 
     * @type {Array<Site>}
     * @memberof PaginatedSiteList
     */
    results: Array<Site>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedSiteList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedSiteList interface.
 */
export function instanceOfPaginatedSiteList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedSiteListFromJSON(json: any): PaginatedSiteList {
    return PaginatedSiteListFromJSONTyped(json, false);
}

export function PaginatedSiteListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedSiteList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(SiteFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedSiteListToJSON(value?: PaginatedSiteList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(SiteToJSON)),
        'num_pages': value['numPages'],
    };
}

