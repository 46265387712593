import { useEffect, useMemo, useRef, useState } from "react";
import { MapBase } from "../../MapV2/Map";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useMapApiClient } from "../../../hooks";
import {
    EmissionImagesLayer,
    EmissionLayerStyle,
    EmissionRecordsLayers,
    PlumeOutlinesLayer,
} from "../../MapV2/layers/emissions";
import {
    EMISSION_LAYER_STYLES,
    MAP_ZOOM_SHOW_DETAILS,
} from "../../MapV2/constants";
import { useMap } from "../../MapV2/hooks/mapState";
import { EmissionHover } from "./Emissions";
import { EquipmentLayer, SiteLayer } from "../../MapV2/layers/infrastructure";
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    EquipmentMapList,
    MapPlumeImagesListExcludeEmissionStatusEnum,
    SiteMapList,
} from "../../../apiClient/generated";
import { PlumeOpacityPanel } from "../../MapV2/elements/DataControls/PlumeControl";
import { createFeatureCollection } from "../../../utils/geopatialUtils";

const useInfrastructurePlumeQueries = (
    enabled: boolean,
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    },
    prefix: string = "",
    siteId?: string,
    tentativeSiteId?: string,
) => {
    const apiClient = useMapApiClient();

    const queryParams = useMemo(
        () => ({
            providerWithSource: filters.providerWithSource?.length
                ? (JSON.stringify(filters.providerWithSource) as any)
                : undefined,
            detectionDateRangeAfter: filters.detectionDateRangeAfter
                ? new Date(filters.detectionDateRangeAfter)
                : undefined,
            detectionDateRangeBefore: filters.detectionDateRangeBefore
                ? new Date(filters.detectionDateRangeBefore)
                : undefined,
            excludeEmissionStatus: [
                "REJECTED",
            ] as MapPlumeImagesListExcludeEmissionStatusEnum[],
        }),
        [filters],
    );

    return useQueries({
        queries: [
            {
                queryKey: [`${prefix}PlumeImagesMiniMap`, siteId, filters],
                queryFn: async () => {
                    const response = await apiClient.mapPlumeImagesList({
                        ...queryParams,
                        site: siteId ? [siteId] : undefined,
                        tentativeSite: tentativeSiteId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
            {
                queryKey: [`${prefix}PlumeOutlinesMiniMap`, siteId, filters],
                queryFn: async () => {
                    const response = await apiClient.mapPlumeOutlinesList({
                        ...queryParams,
                        site: siteId ? [siteId] : undefined,
                        tentativeSite: tentativeSiteId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
        ],
    });
};

const useInfrastructureEmissionQueries = (
    enabled: boolean,
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    },
    prefix: string = "",
    siteId?: string,
    tentativeSiteId?: string,
) => {
    const apiClient = useMapApiClient();

    const queryParams = useMemo(
        () => ({
            providerWithSource: filters.providerWithSource?.length
                ? (JSON.stringify(filters.providerWithSource) as any)
                : undefined,
            detectionDateRangeAfter: filters.detectionDateRangeAfter
                ? new Date(filters.detectionDateRangeAfter)
                : undefined,
            detectionDateRangeBefore: filters.detectionDateRangeBefore
                ? new Date(filters.detectionDateRangeBefore)
                : undefined,
            excludeEmissionStatus: [
                "REJECTED",
            ] as MapPlumeImagesListExcludeEmissionStatusEnum[],
        }),
        [filters],
    );

    return useQueries({
        queries: [
            {
                queryKey: [
                    `${prefix}EmissionRecordsEPAMiniMap`,
                    siteId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapEmissionRecordsList({
                        ...queryParams,
                        dataSource: ["EPA"],
                        site: [siteId],
                        tentativeSite: tentativeSiteId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
            {
                queryKey: [
                    `${prefix}EmissionRecordsSelfReportedMiniMap`,
                    siteId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapEmissionRecordsList({
                        ...queryParams,
                        dataSource: ["SELF_REPORTED"],
                        site: [siteId],
                        tentativeSite: tentativeSiteId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
            {
                queryKey: [
                    `${prefix}EmissionRecordsThirdPartyMiniMap`,
                    siteId,
                    filters,
                ],
                queryFn: async () => {
                    const response = await apiClient.mapEmissionRecordsList({
                        ...queryParams,
                        dataSource: ["THIRD_PARTY"],
                        site: [siteId],
                        tentativeSite: tentativeSiteId,
                    });
                    return response.results;
                },
                refetchOnWindowFocus: false,
                staleTime: 0,
                enabled: enabled,
            },
        ],
    });
};

const useEmissionLayers = (
    site: SiteMapList | undefined,
    equipment: EquipmentMapList[] | undefined,
    emissionQueries: any[],
    plumeQueries: any[],
    tentativeEmissionQueries: any[],
    tentativePlumeQueries: any[],
    options: {
        showPlumes: boolean;
        plumeOpacity: number;
        selectedContext?: string;
        hoverContext?: string;
        cursorPosition?: { x: number; y: number } | null;
        enabledData: string[];
    },
) => {
    const {
        showPlumes,
        plumeOpacity,
        selectedContext,
        hoverContext,
        cursorPosition,
        enabledData,
    } = options;

    return useMemo(() => {
        const layers = [];

        // Look for related plume ID from data
        const contextId = selectedContext || hoverContext;
        let relatedPlume;
        [
            tentativeEmissionQueries[0],
            tentativeEmissionQueries[1],
            tentativeEmissionQueries[2],
            emissionQueries[0],
            emissionQueries[1],
            emissionQueries[2],
        ].forEach((query) => {
            if (!relatedPlume && contextId && query?.data) {
                const found = query.data.find((i) => i.id === contextId);
                if (found?.plumeImage) {
                    relatedPlume = found.plumeImage;
                }
            }
        });

        // Helper for emission layers
        const createEmissionLayer = (
            data: any[],
            layerStyle: EmissionLayerStyle,
            layerPrefix: string,
            layerSuffix: string,
        ) => {
            if (!data) return;
            layers.push(
                EmissionRecordsLayers(
                    createFeatureCollection(
                        data.filter((i) => {
                            if (selectedContext)
                                return i.id === selectedContext;
                            return hoverContext && !cursorPosition
                                ? i.id === hoverContext
                                : true;
                        }),
                    ).features,
                    true,
                    MAP_ZOOM_SHOW_DETAILS + 1,
                    `${layerPrefix}${layerSuffix}`,
                    layerStyle,
                ),
            );
        };

        // First image layers
        if (enabledData.includes("TENTATIVE_EMISSIONS")) {
            if (tentativePlumeQueries[1].data) {
                layers.push(
                    PlumeOutlinesLayer(
                        tentativePlumeQueries[1].data.filter((i) => {
                            if (i.hasPlume && showPlumes) return false;
                            if (selectedContext)
                                return i.emissionRecordIds.includes(
                                    selectedContext,
                                );
                            if (hoverContext)
                                return i.emissionRecordIds.includes(
                                    hoverContext,
                                );
                            return true;
                        }),
                        "tentative_plume_outlines",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }
            if (tentativePlumeQueries[0].data && showPlumes) {
                layers.push(
                    EmissionImagesLayer(
                        tentativePlumeQueries[0].data.filter(
                            (i) =>
                                !contextId ||
                                (relatedPlume && relatedPlume === i.id),
                        ),
                        "tentative_plume_images",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }
        }

        if (enabledData.includes("EMISSIONS")) {
            if (plumeQueries[1].data) {
                layers.push(
                    PlumeOutlinesLayer(
                        plumeQueries[1].data.filter((i) => {
                            if (i.hasPlume && showPlumes) return false;
                            if (selectedContext)
                                return i.emissionRecordIds.includes(
                                    selectedContext,
                                );
                            if (hoverContext)
                                return i.emissionRecordIds.includes(
                                    hoverContext,
                                );
                            return true;
                        }),
                        "emissions_plume_outlines",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }

            if (plumeQueries[0].data && showPlumes) {
                layers.push(
                    EmissionImagesLayer(
                        plumeQueries[0].data.filter(
                            (i) =>
                                !contextId ||
                                (relatedPlume && relatedPlume === i.id),
                        ),
                        "emissions_plume_images",
                        true,
                        MAP_ZOOM_SHOW_DETAILS + 1,
                        plumeOpacity,
                    ),
                );
            }
        }

        // Then infrastructure
        if (site) {
            layers.push(
                SiteLayer(
                    createFeatureCollection([site]).features,
                    undefined,
                    true,
                    16,
                    undefined,
                    false,
                    true,
                ),
            );
        }
        if (equipment && equipment.length > 0) {
            layers.push(
                EquipmentLayer(
                    createFeatureCollection(equipment).features,
                    true,
                    16,
                    undefined,
                    true,
                ),
            );
        }

        // Then emission layers
        if (enabledData.includes("TENTATIVE_EMISSIONS")) {
            createEmissionLayer(
                tentativeEmissionQueries[0].data,
                EMISSION_LAYER_STYLES.epa,
                "tentative_",
                "emissions_epa",
            );
            createEmissionLayer(
                tentativeEmissionQueries[1].data,
                EMISSION_LAYER_STYLES.operatorProvided,
                "tentative_",
                "emissions_operatorProvided",
            );
            createEmissionLayer(
                tentativeEmissionQueries[2].data,
                EMISSION_LAYER_STYLES.thirdParty,
                "tentative_",
                "emissions_thirdParty",
            );
        }
        if (enabledData.includes("EMISSIONS")) {
            createEmissionLayer(
                emissionQueries[0].data,
                EMISSION_LAYER_STYLES.epa,
                "emissions_",
                "emissions_epa",
            );
            createEmissionLayer(
                emissionQueries[1].data,
                EMISSION_LAYER_STYLES.operatorProvided,
                "emissions_",
                "emissions_operatorProvided",
            );
            createEmissionLayer(
                emissionQueries[2].data,
                EMISSION_LAYER_STYLES.thirdParty,
                "emissions_",
                "emissions_thirdParty",
            );
        }

        return layers;
    }, [
        emissionQueries,
        plumeQueries,
        tentativeEmissionQueries,
        tentativePlumeQueries,
        showPlumes,
        plumeOpacity,
        selectedContext,
        hoverContext,
        cursorPosition,
        enabledData,
    ]);
};

interface InfrastructureDetailMapProps {
    infrastructureId: string;
    filters: {
        detectionDateRangeAfter?: string;
        detectionDateRangeBefore?: string;
        providerWithSource?: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    };
    enabledData: string[];
    hoverContext?: string;
    setHoverContext?: (emissionId?: string) => void;
    selectedContext?: string;
    setSelectedContext?: (emissionId?: string) => void;
}

export const InfrastructureDetailMap = (
    props: InfrastructureDetailMapProps,
) => {
    const containerRef = useRef<HTMLDivElement>();
    const apiClient = useMapApiClient();
    const [cursorPosition, setCursorPosition] = useState<{
        x: number;
        y: number;
    } | null>(null);

    const [showPlumes, setShowPlumes] = useState(true);
    const [plumeOpacity, setPlumeOpacity] = useState(0.2);
    const [oldPlumeOpacity, setOldPlumeOpacity] = useState(0);

    // Map setup
    const { flyTo } = useMap("infrastructureDetailMap");

    // Retrieve infrastructure tree
    const siteQuery = useQuery({
        queryKey: ["siteDetailEquipmentList", props.infrastructureId],
        queryFn: async () => {
            const response = await apiClient.mapSitesRetrieve({
                id: props.infrastructureId,
            });
            return response;
        },
    });
    const equipmentQuery = useQuery({
        queryKey: ["equipmentForSite", props.infrastructureId],
        queryFn: async () => {
            const response = await apiClient.mapEquipmentList({
                site: [props.infrastructureId],
            });
            return response.results;
        },
    });

    useEffect(() => {
        if (siteQuery.data) {
            flyTo(
                siteQuery.data.location.coordinates[1],
                siteQuery.data.location.coordinates[0],
                14,
                true,
            );
        }
    }, [siteQuery.data]);

    const emissionQueries = useInfrastructureEmissionQueries(
        props.enabledData.includes("EMISSIONS"),
        props.filters,
        "emissions_",
        props.infrastructureId,
    );
    const plumeQueries = useInfrastructurePlumeQueries(
        props.enabledData.includes("EMISSIONS"),
        props.filters,
        "emissions_",
        props.infrastructureId,
    );
    const tentativeEmissionQueries = useInfrastructureEmissionQueries(
        props.enabledData.includes("TENTATIVE_EMISSIONS"),
        props.filters,
        "tentative_",
        undefined,
        props.infrastructureId,
    );
    const tentativePlumeQueries = useInfrastructurePlumeQueries(
        props.enabledData.includes("TENTATIVE_EMISSIONS"),
        props.filters,
        "tentative_",
        undefined,
        props.infrastructureId,
    );

    const layers = useEmissionLayers(
        siteQuery.data,
        equipmentQuery.data,
        emissionQueries,
        plumeQueries,
        tentativeEmissionQueries,
        tentativePlumeQueries,
        {
            showPlumes: showPlumes,
            plumeOpacity: plumeOpacity,
            selectedContext: props.selectedContext,
            hoverContext: props.hoverContext,
            cursorPosition: cursorPosition,
            enabledData: props.enabledData,
        },
    );

    return (
        <div
            ref={containerRef}
            onMouseLeave={() => setCursorPosition(undefined)}
            className="relative w-full rounded overflow-hidden h-full"
        >
            {props.hoverContext && cursorPosition && (
                <EmissionHover
                    emissionId={props.hoverContext}
                    style={{
                        position: "absolute",
                        left: cursorPosition.x + 10,
                        top: cursorPosition.y + 15,
                        pointerEvents: "none",
                        transform: "translate(-50%, 0)",
                        zIndex: 100,
                    }}
                />
            )}
            <MapBase
                mapId="infrastructureDetailMap"
                layers={layers}
                showScaleControl
                showZoomControl
                showBasemapControl
                showMeasureControl
                onHover={(info) => {
                    if (info.object && info.layer.id.includes("emissions")) {
                        setCursorPosition({ x: info.x, y: info.y });
                        props.setHoverContext(info.object.properties.id);
                    } else {
                        setCursorPosition(null);
                        props.setHoverContext();
                    }
                }}
                onLeftClick={({ info }) => {
                    if (info.length === 0) {
                        props.setSelectedContext();
                    } else if (info[0].layer.id.includes("emissions")) {
                        props.setSelectedContext(
                            info[0].object.properties.id ==
                                props.selectedContext
                                ? undefined
                                : info[0].object.properties.id,
                        );
                    }
                }}
            />
            <div className="absolute top-4 w-full flex justify-center">
                <PlumeOpacityPanel
                    className="w-[90%] overflow-x-scroll"
                    plumeOpacity={plumeOpacity}
                    showPlumes={showPlumes}
                    oldOpacity={oldPlumeOpacity}
                    onOpacityChange={setPlumeOpacity}
                    onToggleVisibility={(oldOpacity) => {
                        setPlumeOpacity(oldOpacity);
                        setOldPlumeOpacity(plumeOpacity);
                    }}
                    onShowPlumesChange={() => setShowPlumes(!showPlumes)}
                />
            </div>
        </div>
    );
};
