/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EquipmentGeometryGeometriesInnerOneOf } from './EquipmentGeometryGeometriesInnerOneOf';
import {
    instanceOfEquipmentGeometryGeometriesInnerOneOf,
    EquipmentGeometryGeometriesInnerOneOfFromJSON,
    EquipmentGeometryGeometriesInnerOneOfFromJSONTyped,
    EquipmentGeometryGeometriesInnerOneOfToJSON,
} from './EquipmentGeometryGeometriesInnerOneOf';
import type { EquipmentGeometryGeometriesInnerOneOf1 } from './EquipmentGeometryGeometriesInnerOneOf1';
import {
    instanceOfEquipmentGeometryGeometriesInnerOneOf1,
    EquipmentGeometryGeometriesInnerOneOf1FromJSON,
    EquipmentGeometryGeometriesInnerOneOf1FromJSONTyped,
    EquipmentGeometryGeometriesInnerOneOf1ToJSON,
} from './EquipmentGeometryGeometriesInnerOneOf1';
import type { EquipmentGeometryGeometriesInnerOneOf2 } from './EquipmentGeometryGeometriesInnerOneOf2';
import {
    instanceOfEquipmentGeometryGeometriesInnerOneOf2,
    EquipmentGeometryGeometriesInnerOneOf2FromJSON,
    EquipmentGeometryGeometriesInnerOneOf2FromJSONTyped,
    EquipmentGeometryGeometriesInnerOneOf2ToJSON,
} from './EquipmentGeometryGeometriesInnerOneOf2';
import type { EquipmentGeometryGeometriesInnerOneOf3 } from './EquipmentGeometryGeometriesInnerOneOf3';
import {
    instanceOfEquipmentGeometryGeometriesInnerOneOf3,
    EquipmentGeometryGeometriesInnerOneOf3FromJSON,
    EquipmentGeometryGeometriesInnerOneOf3FromJSONTyped,
    EquipmentGeometryGeometriesInnerOneOf3ToJSON,
} from './EquipmentGeometryGeometriesInnerOneOf3';
import type { EquipmentGeometryGeometriesInnerOneOf4 } from './EquipmentGeometryGeometriesInnerOneOf4';
import {
    instanceOfEquipmentGeometryGeometriesInnerOneOf4,
    EquipmentGeometryGeometriesInnerOneOf4FromJSON,
    EquipmentGeometryGeometriesInnerOneOf4FromJSONTyped,
    EquipmentGeometryGeometriesInnerOneOf4ToJSON,
} from './EquipmentGeometryGeometriesInnerOneOf4';
import type { EquipmentGeometryGeometriesInnerOneOf5 } from './EquipmentGeometryGeometriesInnerOneOf5';
import {
    instanceOfEquipmentGeometryGeometriesInnerOneOf5,
    EquipmentGeometryGeometriesInnerOneOf5FromJSON,
    EquipmentGeometryGeometriesInnerOneOf5FromJSONTyped,
    EquipmentGeometryGeometriesInnerOneOf5ToJSON,
} from './EquipmentGeometryGeometriesInnerOneOf5';

/**
 * @type EquipmentGeometryGeometriesInner
 * 
 * @export
 */
export type EquipmentGeometryGeometriesInner = EquipmentGeometryGeometriesInnerOneOf | EquipmentGeometryGeometriesInnerOneOf1 | EquipmentGeometryGeometriesInnerOneOf2 | EquipmentGeometryGeometriesInnerOneOf3 | EquipmentGeometryGeometriesInnerOneOf4 | EquipmentGeometryGeometriesInnerOneOf5;

export function EquipmentGeometryGeometriesInnerFromJSON(json: any): EquipmentGeometryGeometriesInner {
    return EquipmentGeometryGeometriesInnerFromJSONTyped(json, false);
}

export function EquipmentGeometryGeometriesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGeometryGeometriesInner {
    if (json == null) {
        return json;
    }
    return EquipmentGeometryGeometriesInnerOneOfFromJSONTyped(json, true) || EquipmentGeometryGeometriesInnerOneOf1FromJSONTyped(json, true) || EquipmentGeometryGeometriesInnerOneOf2FromJSONTyped(json, true) || EquipmentGeometryGeometriesInnerOneOf3FromJSONTyped(json, true) || EquipmentGeometryGeometriesInnerOneOf4FromJSONTyped(json, true) || EquipmentGeometryGeometriesInnerOneOf5FromJSONTyped(json, true);
}

export function EquipmentGeometryGeometriesInnerToJSON(value?: EquipmentGeometryGeometriesInner | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfEquipmentGeometryGeometriesInnerOneOf(value)) {
        return EquipmentGeometryGeometriesInnerOneOfToJSON(value as EquipmentGeometryGeometriesInnerOneOf);
    }
    if (instanceOfEquipmentGeometryGeometriesInnerOneOf1(value)) {
        return EquipmentGeometryGeometriesInnerOneOf1ToJSON(value as EquipmentGeometryGeometriesInnerOneOf1);
    }
    if (instanceOfEquipmentGeometryGeometriesInnerOneOf2(value)) {
        return EquipmentGeometryGeometriesInnerOneOf2ToJSON(value as EquipmentGeometryGeometriesInnerOneOf2);
    }
    if (instanceOfEquipmentGeometryGeometriesInnerOneOf3(value)) {
        return EquipmentGeometryGeometriesInnerOneOf3ToJSON(value as EquipmentGeometryGeometriesInnerOneOf3);
    }
    if (instanceOfEquipmentGeometryGeometriesInnerOneOf4(value)) {
        return EquipmentGeometryGeometriesInnerOneOf4ToJSON(value as EquipmentGeometryGeometriesInnerOneOf4);
    }
    if (instanceOfEquipmentGeometryGeometriesInnerOneOf5(value)) {
        return EquipmentGeometryGeometriesInnerOneOf5ToJSON(value as EquipmentGeometryGeometriesInnerOneOf5);
    }

    return {};
}

