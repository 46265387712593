/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SITE` - Site
 * * `EQUIPMENT_GROUP` - Equipment Group
 * * `EQUIPMENT` - Equipment
 * * `EQUIPMENT_COMPONENT` - Equipment Component
 * @export
 */
export const EquipmentTypeEnum = {
    Site: 'SITE',
    EquipmentGroup: 'EQUIPMENT_GROUP',
    Equipment: 'EQUIPMENT',
    EquipmentComponent: 'EQUIPMENT_COMPONENT'
} as const;
export type EquipmentTypeEnum = typeof EquipmentTypeEnum[keyof typeof EquipmentTypeEnum];


export function instanceOfEquipmentTypeEnum(value: any): boolean {
    return Object.values(EquipmentTypeEnum).includes(value);
}

export function EquipmentTypeEnumFromJSON(json: any): EquipmentTypeEnum {
    return EquipmentTypeEnumFromJSONTyped(json, false);
}

export function EquipmentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentTypeEnum {
    return json as EquipmentTypeEnum;
}

export function EquipmentTypeEnumToJSON(value?: EquipmentTypeEnum | null): any {
    return value as any;
}

