import { DashboardTabsV2 } from "../../../ui/Card";
import { EquipmentApiDataTable } from "./Equipment";
import { SiteApiDataTable } from "./Sites";

// TODO: finish this page
export const InfrastructureListPage = () => {
    return (
        <DashboardTabsV2
            tabs={[
                {
                    name: "Sites",
                    child: <SiteApiDataTable />,
                },
                {
                    name: "Equipment",
                    child: <EquipmentApiDataTable />,
                },
            ]}
        />
    );
};
