/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EpaNotification } from './EpaNotification';
import {
    EpaNotificationFromJSON,
    EpaNotificationFromJSONTyped,
    EpaNotificationToJSON,
} from './EpaNotification';
import type { EpaOperatorNotificationStatusEnum } from './EpaOperatorNotificationStatusEnum';
import {
    EpaOperatorNotificationStatusEnumFromJSON,
    EpaOperatorNotificationStatusEnumFromJSONTyped,
    EpaOperatorNotificationStatusEnumToJSON,
} from './EpaOperatorNotificationStatusEnum';
import type { NotificationReasonEnum } from './NotificationReasonEnum';
import {
    NotificationReasonEnumFromJSON,
    NotificationReasonEnumFromJSONTyped,
    NotificationReasonEnumToJSON,
} from './NotificationReasonEnum';
import type { SimpleDataPoint } from './SimpleDataPoint';
import {
    SimpleDataPointFromJSON,
    SimpleDataPointFromJSONTyped,
    SimpleDataPointToJSON,
} from './SimpleDataPoint';
import type { SimpleInfrastructure } from './SimpleInfrastructure';
import {
    SimpleInfrastructureFromJSON,
    SimpleInfrastructureFromJSONTyped,
    SimpleInfrastructureToJSON,
} from './SimpleInfrastructure';
import type { Site } from './Site';
import {
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
} from './Site';

/**
 * Serializer for the EpaOperatorNotification model.
 * @export
 * @interface EpaOperatorNotification
 */
export interface EpaOperatorNotification {
    /**
     * 
     * @type {string}
     * @memberof EpaOperatorNotification
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof EpaOperatorNotification
     */
    readonly owner: number;
    /**
     * 
     * @type {string}
     * @memberof EpaOperatorNotification
     */
    readonly epaForm: string;
    /**
     * 
     * @type {EpaNotification}
     * @memberof EpaOperatorNotification
     */
    notification: EpaNotification;
    /**
     * 
     * @type {string}
     * @memberof EpaOperatorNotification
     */
    readonly assigneeName: string;
    /**
     * 
     * @type {SimpleInfrastructure}
     * @memberof EpaOperatorNotification
     */
    nearestSite: SimpleInfrastructure | null;
    /**
     * 
     * @type {Array<SimpleInfrastructure>}
     * @memberof EpaOperatorNotification
     */
    otherNearSites: Array<SimpleInfrastructure> | null;
    /**
     * 
     * @type {Site}
     * @memberof EpaOperatorNotification
     */
    nearestSiteV2: Site | null;
    /**
     * 
     * @type {Array<Site>}
     * @memberof EpaOperatorNotification
     */
    otherNearSitesV2: Array<Site> | null;
    /**
     * 
     * @type {SimpleDataPoint}
     * @memberof EpaOperatorNotification
     */
    dataPoint: SimpleDataPoint | null;
    /**
     * 
     * @type {string}
     * @memberof EpaOperatorNotification
     */
    epaEmissionRecord?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaOperatorNotification
     */
    readonly epaIdentifiedSite: string;
    /**
     * 
     * @type {number}
     * @memberof EpaOperatorNotification
     */
    assignee?: number;
    /**
     * 
     * @type {NotificationReasonEnum}
     * @memberof EpaOperatorNotification
     */
    readonly notificationReason: NotificationReasonEnum;
    /**
     * 
     * @type {EpaOperatorNotificationStatusEnum}
     * @memberof EpaOperatorNotification
     */
    status?: EpaOperatorNotificationStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof EpaOperatorNotification
     */
    readonly investigationStartedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EpaOperatorNotification
     */
    readonly completedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EpaOperatorNotification
     */
    readonly submittedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EpaOperatorNotification
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EpaOperatorNotification
     */
    readonly updatedAt: Date | null;
}

/**
 * Check if a given object implements the EpaOperatorNotification interface.
 */
export function instanceOfEpaOperatorNotification(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('epaForm' in value)) return false;
    if (!('notification' in value)) return false;
    if (!('assigneeName' in value)) return false;
    if (!('nearestSite' in value)) return false;
    if (!('otherNearSites' in value)) return false;
    if (!('nearestSiteV2' in value)) return false;
    if (!('otherNearSitesV2' in value)) return false;
    if (!('dataPoint' in value)) return false;
    if (!('epaIdentifiedSite' in value)) return false;
    if (!('notificationReason' in value)) return false;
    if (!('investigationStartedAt' in value)) return false;
    if (!('completedAt' in value)) return false;
    if (!('submittedAt' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function EpaOperatorNotificationFromJSON(json: any): EpaOperatorNotification {
    return EpaOperatorNotificationFromJSONTyped(json, false);
}

export function EpaOperatorNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaOperatorNotification {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'epaForm': json['epa_form'],
        'notification': EpaNotificationFromJSON(json['notification']),
        'assigneeName': json['assignee_name'],
        'nearestSite': SimpleInfrastructureFromJSON(json['nearest_site']),
        'otherNearSites': (json['other_near_sites'] == null ? null : (json['other_near_sites'] as Array<any>).map(SimpleInfrastructureFromJSON)),
        'nearestSiteV2': SiteFromJSON(json['nearest_site_v2']),
        'otherNearSitesV2': (json['other_near_sites_v2'] == null ? null : (json['other_near_sites_v2'] as Array<any>).map(SiteFromJSON)),
        'dataPoint': SimpleDataPointFromJSON(json['data_point']),
        'epaEmissionRecord': json['epa_emission_record'] == null ? undefined : json['epa_emission_record'],
        'epaIdentifiedSite': json['epa_identified_site'],
        'assignee': json['assignee'] == null ? undefined : json['assignee'],
        'notificationReason': NotificationReasonEnumFromJSON(json['notification_reason']),
        'status': json['status'] == null ? undefined : EpaOperatorNotificationStatusEnumFromJSON(json['status']),
        'investigationStartedAt': (json['investigation_started_at'] == null ? null : new Date(json['investigation_started_at'])),
        'completedAt': (json['completed_at'] == null ? null : new Date(json['completed_at'])),
        'submittedAt': (json['submitted_at'] == null ? null : new Date(json['submitted_at'])),
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
    };
}

export function EpaOperatorNotificationToJSON(value?: Omit<EpaOperatorNotification, 'id'|'owner'|'epa_form'|'assignee_name'|'epa_identified_site'|'notification_reason'|'investigation_started_at'|'completed_at'|'submitted_at'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notification': EpaNotificationToJSON(value['notification']),
        'nearest_site': SimpleInfrastructureToJSON(value['nearestSite']),
        'other_near_sites': (value['otherNearSites'] == null ? null : (value['otherNearSites'] as Array<any>).map(SimpleInfrastructureToJSON)),
        'nearest_site_v2': SiteToJSON(value['nearestSiteV2']),
        'other_near_sites_v2': (value['otherNearSitesV2'] == null ? null : (value['otherNearSitesV2'] as Array<any>).map(SiteToJSON)),
        'data_point': SimpleDataPointToJSON(value['dataPoint']),
        'epa_emission_record': value['epaEmissionRecord'],
        'assignee': value['assignee'],
        'status': EpaOperatorNotificationStatusEnumToJSON(value['status']),
    };
}

