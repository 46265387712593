import { useInfrastructureApiClient } from "../../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import { CoordinatesField } from "../../../ui/GenericFields";
import { SearchInput } from "../../../ui/Inputs";
import { useMemo, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { LocationFilterV2 } from "../../DataTable/TableFilters";
import { DateTime } from "luxon";
import { Equipment } from "../../../apiClient/generated";
import { DataTableV3 } from "../../DataTable/DataTableV3";
import { useAtom } from "jotai";
import { tableStateFamily } from "../../DataTable/state";
import { SecondaryButton } from "../../../ui/Buttons";
import { useResetAtom } from "jotai/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/sharp-light-svg-icons";

const columnHelper = createColumnHelper<Equipment>();

export const EquipmentApiDataTable = () => {
    const apiClient = useInfrastructureApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // Table state
    const tableAtom = useMemo(
        () =>
            tableStateFamily({
                tableId: "equipment",
                initialState: {
                    columnVisibility: {
                        infraStatus: false,
                        dateOfInstallation: false,
                    },
                },
            }),
        [],
    );
    useAtom(tableAtom);
    const resetTableState = useResetAtom(tableAtom);

    const fetchFunction = async (props: any) => {
        return await apiClient.infrastructureEquipmentList(props);
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor("siteName", {
                id: "site",
                header: "Site",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("location", {
                id: "location",
                header: () => "Location",
                cell: (info) => {
                    const value = info.getValue();
                    if (value) {
                        return (
                            <CoordinatesField
                                coordinates={value.coordinates}
                                allowCopy={true}
                            />
                        );
                    }
                    return "-";
                },
                size: 160,
                meta: {
                    customFilterWidget: LocationFilterV2,
                },
                enableSorting: false,
            }),
            columnHelper.accessor("manufacturerMake", {
                id: "manufacturerMake",
                cell: (info) => (
                    <div className="overflow-hidden truncate">
                        {info.getValue()}
                    </div>
                ),
                header: () => <span>Manufacturer</span>,
            }),
            columnHelper.accessor("manufacturerModel", {
                id: "manufacturerModel",
                cell: (info) => info.getValue(),
                header: () => <span>Model</span>,
            }),
            columnHelper.accessor("operatorStatus", {
                id: "operatorStatus",
                cell: (info) => info.getValue(),
                header: () => <span>Status</span>,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("dateOfInstallation", {
                id: "dateOfInstallation",
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        value &&
                        DateTime.fromJSDate(value)
                            .setZone("utc")
                            .toLocaleString(DateTime.DATE_SHORT)
                    );
                },
                header: () => <span>Installation Date</span>,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("material", {
                id: "material",
                cell: (info) => info.getValue(),
                header: () => <span>Material</span>,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("fuel", {
                id: "fuel",
                cell: (info) => info.getValue(),
                header: () => <span>Fuel</span>,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor(
                (row) => ({
                    operatorUniqueId: row.operatorUniqueId,
                    otherOperatorIds: row.otherOperatorIds,
                }),
                {
                    id: "operatorUniqueIds",
                    header: () => "Operator IDs",
                    cell: (info) => {
                        const value = info.getValue();
                        let opIds = "";
                        if (value.operatorUniqueId.trim()) {
                            opIds = value.operatorUniqueId;
                        }
                        if (value.otherOperatorIds.trim()) {
                            opIds += value.otherOperatorIds
                                .split("¦")
                                .join(", ");
                        }
                        if (opIds.trim()) {
                            return opIds;
                        }
                        return "-";
                    },
                    enableSorting: false,
                    enableColumnFilter: false,
                },
            ),
        ],
        [],
    );

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <SearchInput
                    placeholder="Search by name, type, facility..."
                    value={filterSearch}
                    onChange={(newValue) => setFilterSearch(newValue)}
                    className="w-96"
                />
                <div className="flex-grow" />
                <div className="flex gap-2 items-center">
                    <SecondaryButton variant="sm" onClick={resetTableState}>
                        <FontAwesomeIcon
                            icon={faFilterCircleXmark}
                            className="w-4"
                        />
                        Clear filters
                    </SecondaryButton>
                </div>
            </div>

            {/* Data table */}
            <DataTableV3<Equipment>
                dataName="equipment"
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={true}
            />
        </div>
    );
};
