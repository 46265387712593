/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipmentGeometryGeometriesInnerOneOf5
 */
export interface EquipmentGeometryGeometriesInnerOneOf5 {
    /**
     * MultiPolygon geometry type
     * @type {string}
     * @memberof EquipmentGeometryGeometriesInnerOneOf5
     */
    type: EquipmentGeometryGeometriesInnerOneOf5TypeEnum;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof EquipmentGeometryGeometriesInnerOneOf5
     */
    coordinates: Array<Array<Array<Array<number>>>>;
}


/**
 * @export
 */
export const EquipmentGeometryGeometriesInnerOneOf5TypeEnum = {
    MultiPolygon: 'MultiPolygon'
} as const;
export type EquipmentGeometryGeometriesInnerOneOf5TypeEnum = typeof EquipmentGeometryGeometriesInnerOneOf5TypeEnum[keyof typeof EquipmentGeometryGeometriesInnerOneOf5TypeEnum];


/**
 * Check if a given object implements the EquipmentGeometryGeometriesInnerOneOf5 interface.
 */
export function instanceOfEquipmentGeometryGeometriesInnerOneOf5(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('coordinates' in value)) return false;
    return true;
}

export function EquipmentGeometryGeometriesInnerOneOf5FromJSON(json: any): EquipmentGeometryGeometriesInnerOneOf5 {
    return EquipmentGeometryGeometriesInnerOneOf5FromJSONTyped(json, false);
}

export function EquipmentGeometryGeometriesInnerOneOf5FromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGeometryGeometriesInnerOneOf5 {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'coordinates': json['coordinates'],
    };
}

export function EquipmentGeometryGeometriesInnerOneOf5ToJSON(value?: EquipmentGeometryGeometriesInnerOneOf5 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

