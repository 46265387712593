/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Equipment } from './Equipment';
import {
    EquipmentFromJSON,
    EquipmentFromJSONTyped,
    EquipmentToJSON,
} from './Equipment';

/**
 * 
 * @export
 * @interface PaginatedEquipmentList
 */
export interface PaginatedEquipmentList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedEquipmentList
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedEquipmentList
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginatedEquipmentList
     */
    previous?: string;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof PaginatedEquipmentList
     */
    results: Array<Equipment>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedEquipmentList
     */
    numPages?: number;
}

/**
 * Check if a given object implements the PaginatedEquipmentList interface.
 */
export function instanceOfPaginatedEquipmentList(value: object): boolean {
    if (!('count' in value)) return false;
    if (!('results' in value)) return false;
    return true;
}

export function PaginatedEquipmentListFromJSON(json: any): PaginatedEquipmentList {
    return PaginatedEquipmentListFromJSONTyped(json, false);
}

export function PaginatedEquipmentListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedEquipmentList {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(EquipmentFromJSON)),
        'numPages': json['num_pages'] == null ? undefined : json['num_pages'],
    };
}

export function PaginatedEquipmentListToJSON(value?: PaginatedEquipmentList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(EquipmentToJSON)),
        'num_pages': value['numPages'],
    };
}

