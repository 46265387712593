import { useEmissionEventsApiClient } from "../../hooks";
import { createColumnHelper } from "@tanstack/react-table";
import {
    EmissionEventFull,
    EmissionStatusEnum,
    EmissioneventsListDataSourceEnum,
    EventStatusEnum,
} from "../../apiClient/generated";
import { CoordinatesField } from "../../ui/GenericFields";
import { useMemo, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { DateTime } from "luxon";
import { PrimaryButton, SecondaryButton } from "../../ui/Buttons";
import { SearchInput } from "../../ui/Inputs";
import {
    AssignedToFilter,
    DataProviderFilterV2,
    DateRangeFilter,
    LocationFilterV2,
    RangeFilter,
} from "../DataTable/TableFilters";
import { RateWithUncertaintyCell } from "../Emissions/Extras";
import { DataTableV3 } from "../DataTable/DataTableV3";
import { useAtom } from "jotai";
import { tableStateFamily } from "../DataTable/state";
import { useResetAtom } from "jotai/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/sharp-light-svg-icons";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const columnHelper = createColumnHelper<EmissionEventFull>();
const baseEmissionColumns = [
    columnHelper.accessor((row) => row.emissionRecords, {
        id: "detectedRate",
        meta: {
            customFilterWidget: RangeFilter,
            sortingKey: "main_emission_record__data_point__detected_rate",
        },
        cell: (info) => {
            const emissionRecords = info.getValue();
            if (emissionRecords.length === 0) {
                return "-";
            }
            const rate = emissionRecords[0].dataPoint.detectedRate;
            const rateUncertainty =
                emissionRecords[0].dataPoint.detectedRateUncertainty;
            for (const record of emissionRecords) {
                if (record.dataPoint.detectedRate !== rate) {
                    return (
                        <span className="italic text-gray-500">Multiple</span>
                    );
                }
            }
            return (
                <RateWithUncertaintyCell
                    rate={rate ? rate / 1000 : rate}
                    uncertainty={
                        rateUncertainty
                            ? rateUncertainty / 1000
                            : rateUncertainty
                    }
                    unit="kg/h"
                />
            );
        },
        header: () => "Detection rate",
    }),
    columnHelper.accessor((row) => row.emissionRecords, {
        id: "concentration",
        meta: {
            customFilterWidget: RangeFilter,
            sortingKey: "main_emission_record__data_point__concentration",
        },
        cell: (info) => {
            const emissionRecords = info.getValue();
            if (emissionRecords.length === 0) {
                return "-";
            }
            const concentration = emissionRecords[0].dataPoint.concentration;
            const concentrationUncertainty =
                emissionRecords[0].dataPoint.concentrationUncertainty;
            for (const record of emissionRecords) {
                if (record.dataPoint.concentration !== concentration) {
                    return <span className="italic">Multiple</span>;
                }
            }
            return (
                <RateWithUncertaintyCell
                    rate={concentration}
                    uncertainty={concentrationUncertainty}
                    unit="ppm*m"
                />
            );
        },
        header: () => "Concentration",
    }),
    columnHelper.accessor((row) => row.mainEmissionRecord, {
        id: "mainEmissionRecordInfrastructure",
        meta: {
            sortingKey: "main_emission_record__infrastructure__site_name",
        },
        cell: (info) => {
            const value = info.getValue();
            return (
                <div className="overflow-hidden truncate">
                    {value?.site ? (
                        value.site.siteName
                    ) : (
                        <span className="italic">
                            No associated infrastructure.
                        </span>
                    )}
                </div>
            );
        },
        header: () => <span>Infrastructure</span>,
        enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.emissionRecords, {
        id: "provider",
        cell: (info) => {
            const emissionRecords = info.getValue();
            const uniqueProviders = Array.from(
                new Set(emissionRecords.map((r) => r.dataPoint.providerName)),
            );
            const uniqueSources = Array.from(
                new Set(
                    emissionRecords.map((r) => r.dataPoint.secondaryDataSource),
                ),
            );

            const providers = uniqueProviders.join(", ");
            const sources =
                uniqueSources.length > 0 ? uniqueSources.join(", ") : "";

            return (
                <span>
                    {providers} {sources.trim() !== "" ? `(${sources})` : ""}
                </span>
            );
        },
        header: () => "Provider",
        enableSorting: false,
        meta: {
            customFilterWidget: DataProviderFilterV2,
        },
    }),
    columnHelper.accessor((row) => row.responsibleName, {
        id: "responsible",
        meta: {
            filteringKey: "assignedTo",
            customFilterWidget: AssignedToFilter,
        },
        cell: (info) => info.getValue(),
        header: () => "Assignee",
        enableColumnFilter: true,
        enableSorting: false,
    }),
    columnHelper.accessor((row) => row.mainEmissionRecord?.dataPoint, {
        id: "detectionTimestamp",
        meta: {
            sortingKey: "main_emission_record__data_point__detection_timestamp",
            filteringKey: "detectionDateRange",
            customFilterWidget: DateRangeFilter,
        },
        cell: (info) => {
            if (!info.getValue()) {
                return "-";
            }
            return DateTime.fromJSDate(info.getValue().detectionTimestamp)
                .setZone("utc")
                .toLocaleString(DateTime.DATETIME_SHORT);
        },
        header: () => "Detection Date (UTC)",
    }),
    columnHelper.accessor("createdAt", {
        id: "createdAt",
        meta: {
            customFilterWidget: DateRangeFilter,
        },
        cell: (info) => {
            if (!info.getValue()) {
                return "-";
            }
            return DateTime.fromJSDate(info.getValue())
                .setZone("utc")
                .toLocaleString(DateTime.DATETIME_SHORT);
        },
        header: () => "Import Date (UTC)",
    }),
    columnHelper.accessor((row) => row.mainEmissionRecord, {
        id: "location",
        cell: (info) => (
            <CoordinatesField
                coordinates={info.getValue()?.dataPoint.location.coordinates}
                allowCopy
            />
        ),
        header: () => "Location",
        enableColumnFilter: true,
        enableSorting: false,
        meta: {
            customFilterWidget: LocationFilterV2,
        },
    }),
    columnHelper.accessor((row) => row.emissionRecords, {
        id: "operatorIds",
        cell: (info) => {
            const emissions = info.getValue();
            if (Array.isArray(emissions)) {
                const infraWithOperatorIds = emissions.filter(
                    (i) => i.site && i.site.operatorUniqueId.trim(),
                );
                if (infraWithOperatorIds.length > 1) {
                    return (
                        <span
                            className="flex gap-1 items-center italic text-gray-500"
                            title={infraWithOperatorIds
                                .map((i) => i.site.operatorUniqueId)
                                .join(", ")}
                        >
                            Multiple
                            <QuestionMarkCircleIcon className="h-4" />
                        </span>
                    );
                }
                if (
                    infraWithOperatorIds.length == 1 &&
                    infraWithOperatorIds[0].site.operatorUniqueId.trim()
                ) {
                    return infraWithOperatorIds[0].site.operatorUniqueId;
                }
            }
            return "";
        },
        header: () => "Operator Unique ID",
        enableColumnFilter: false,
        enableSorting: false,
    }),
];

interface ApiDataTable {
    showStatus?: boolean;
    dataSource?: EmissioneventsListDataSourceEnum[];
    emissionStatus?: EmissionStatusEnum[];
    eventStatus?: EventStatusEnum[];
    onClickRow?: (id: string | number) => void;
}

export const EventsApiDataTable = (props: ApiDataTable) => {
    const { dataSource, eventStatus, emissionStatus } = props;
    const apiClient = useEmissionEventsApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // Set initial table state
    const tableAtom = useMemo(
        () =>
            tableStateFamily({
                tableId: `events-${dataSource}`,
                initialState: {
                    sorting: {
                        id: "emission_records__data_point__detection_timestamp",
                        desc: true,
                    },
                },
            }),
        [dataSource],
    );
    useAtom(tableAtom);
    const resetTableState = useResetAtom(tableAtom);

    const fetchFunction = async (props: any) => {
        return await apiClient.emissioneventsList(props);
    };

    const columns = useMemo(() => {
        return [
            columnHelper.display({
                id: "actions",
                header: "Actions",
                cell: (info) => (
                    <PrimaryButton
                        variant="sm"
                        onClick={() =>
                            // Send the main emissionId instead of event ID to open modal.
                            props.onClickRow(
                                info.row.original.mainEmissionRecord?.id,
                            )
                        }
                    >
                        {info.row.original.eventStatus === "COMPLETED"
                            ? "View"
                            : "Manage"}
                    </PrimaryButton>
                ),
                enableColumnFilter: false,
            }),
            ...baseEmissionColumns,
        ];
    }, [props]);

    return (
        <div className="flex-1 flex flex-col">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <SearchInput
                    placeholder="Search by infrastructure name..."
                    containerClassName="w-96"
                    value={filterSearch}
                    onChange={(newValue) => setFilterSearch(newValue)}
                />
                <SecondaryButton variant="sm" onClick={resetTableState}>
                    <FontAwesomeIcon
                        icon={faFilterCircleXmark}
                        className="w-4"
                    />
                    Clear filters
                </SecondaryButton>
            </div>

            {/* Data table */}
            <DataTableV3<EmissionEventFull>
                dataName={`events-${props.dataSource}`}
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                    hasEvents: true,
                    dataSource,
                    eventStatus,
                    emissionStatus,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={true}
            />
        </div>
    );
};
