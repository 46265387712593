/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemShape } from './AdminInfrastructureImportItemShape';
import {
    AdminInfrastructureImportItemShapeFromJSON,
    AdminInfrastructureImportItemShapeFromJSONTyped,
    AdminInfrastructureImportItemShapeToJSON,
} from './AdminInfrastructureImportItemShape';
import type { EpaNotificationLocation } from './EpaNotificationLocation';
import {
    EpaNotificationLocationFromJSON,
    EpaNotificationLocationFromJSONTyped,
    EpaNotificationLocationToJSON,
} from './EpaNotificationLocation';

/**
 * Site serializer, displaying all non-relational site fields.
 * @export
 * @interface SiteRequest
 */
export interface SiteRequest {
    /**
     * 
     * @type {string}
     * @memberof SiteRequest
     */
    siteName?: string;
    /**
     * 
     * @type {Date}
     * @memberof SiteRequest
     */
    dateOfInstallation?: Date;
    /**
     * 
     * @type {string}
     * @memberof SiteRequest
     */
    operatorStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequest
     */
    subpartWSegment?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequest
     */
    operatorUniqueId?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteRequest
     */
    otherOperatorIds?: string;
    /**
     * 
     * @type {number}
     * @memberof SiteRequest
     */
    owner?: number;
    /**
     * 
     * @type {EpaNotificationLocation}
     * @memberof SiteRequest
     */
    location: EpaNotificationLocation;
    /**
     * 
     * @type {AdminInfrastructureImportItemShape}
     * @memberof SiteRequest
     */
    shape?: AdminInfrastructureImportItemShape;
    /**
     * 
     * @type {Date}
     * @memberof SiteRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SiteRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {any}
     * @memberof SiteRequest
     */
    extraData?: any;
}

/**
 * Check if a given object implements the SiteRequest interface.
 */
export function instanceOfSiteRequest(value: object): boolean {
    if (!('location' in value)) return false;
    return true;
}

export function SiteRequestFromJSON(json: any): SiteRequest {
    return SiteRequestFromJSONTyped(json, false);
}

export function SiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'siteName': json['site_name'] == null ? undefined : json['site_name'],
        'dateOfInstallation': json['date_of_installation'] == null ? undefined : (new Date(json['date_of_installation'])),
        'operatorStatus': json['operator_status'] == null ? undefined : json['operator_status'],
        'subpartWSegment': json['subpart_w_segment'] == null ? undefined : json['subpart_w_segment'],
        'operatorUniqueId': json['operator_unique_id'] == null ? undefined : json['operator_unique_id'],
        'otherOperatorIds': json['other_operator_ids'] == null ? undefined : json['other_operator_ids'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'location': EpaNotificationLocationFromJSON(json['location']),
        'shape': json['shape'] == null ? undefined : AdminInfrastructureImportItemShapeFromJSON(json['shape']),
        'startDate': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'endDate': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
    };
}

export function SiteRequestToJSON(value?: SiteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'site_name': value['siteName'],
        'date_of_installation': value['dateOfInstallation'] == null ? undefined : ((value['dateOfInstallation'] as any).toISOString().substring(0,10)),
        'operator_status': value['operatorStatus'],
        'subpart_w_segment': value['subpartWSegment'],
        'operator_unique_id': value['operatorUniqueId'],
        'other_operator_ids': value['otherOperatorIds'],
        'owner': value['owner'],
        'location': EpaNotificationLocationToJSON(value['location']),
        'shape': AdminInfrastructureImportItemShapeToJSON(value['shape']),
        'start_date': value['startDate'] == null ? undefined : ((value['startDate'] as any).toISOString().substring(0,10)),
        'end_date': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString().substring(0,10)),
        'extra_data': value['extraData'],
    };
}

