import { Polygon } from "@deck.gl-community/editable-layers";
import { EpaOperatorNotification } from "../../../apiClient/generated";
import { customDistanceToPolygon } from "../../../utils/geopatialUtils";
import * as turf from "@turf/turf";

export const computeNearestSite = (notification: EpaOperatorNotification) => {
    // Calculate distance
    let distance: number = null;
    let site = notification.nearestSiteV2;
    const emissionLocation = notification.dataPoint?.location;
    // This should not happen, but it did, so adding a safeguard.
    if (!emissionLocation) {
        return { site: null, distance: null };
    }

    // If there's only one site.
    if (site && (site.location || site.shape)) {
        if (site.shape) {
            distance = customDistanceToPolygon(
                site.shape as Polygon,
                emissionLocation,
            );
        } else if (site.location) {
            distance = turf.distance(emissionLocation, site.location, {
                units: "meters",
            });
        }
    }
    // If there's multiple sites, find closest.
    else if (
        notification.otherNearSitesV2 &&
        notification.otherNearSitesV2.length > 0
    ) {
        for (const nearbySite of notification.otherNearSitesV2) {
            let d: number = null;
            if (nearbySite.shape) {
                d = customDistanceToPolygon(
                    nearbySite.shape as Polygon,
                    emissionLocation,
                );
            } else if (nearbySite.location) {
                d = turf.distance(emissionLocation, nearbySite.location, {
                    units: "meters",
                });
            }
            if (distance === null || distance >= d) {
                distance = d;
                site = nearbySite;
            }
        }
    }

    return {
        site,
        distance,
    };
};
