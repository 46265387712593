import { useQuery } from "@tanstack/react-query";
import { PickingInfo } from "deck.gl";
import {
    useDataPointsApi,
    useEmissionRecordsApiClient,
    useInfrastructureApiClient,
} from "../../hooks";
import { DateTime } from "luxon";
import { useFloating, offset, shift, flip } from "@floating-ui/react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faTriangleExclamation,
} from "@fortawesome/sharp-regular-svg-icons";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

export interface PickedItems {
    pointClicked: {
        x: number;
        y: number;
    };
    sites: PickingInfo[];
    equipment: PickingInfo[];
    pipelines: PickingInfo[];
    emissions: PickingInfo[];
    dataPoints: PickingInfo[];
}

const SiteItem = ({
    siteId,
    onClick,
}: {
    siteId: string;
    onClick: () => void;
}) => {
    const apiClient = useInfrastructureApiClient();
    const siteQuery = useQuery({
        queryKey: ["site", siteId],
        queryFn: async () => {
            const response = await apiClient.infrastructureSitesRetrieve({
                id: siteId,
            });
            return response;
        },
        staleTime: Infinity,
    });

    if (!siteQuery.data) {
        return (
            <div className="w-32 py-2 flex items-center justify-center">
                <FontAwesomeIcon
                    icon={faSpinner}
                    className="w-4 animate-spin"
                />
            </div>
        );
    }

    return (
        <button
            className="px-2 py-1 rounded flex flex-col hover:bg-gray-100 w-full whitespace-nowrap"
            onClick={onClick}
        >
            <span className="text-left">{siteQuery.data.siteName}</span>
        </button>
    );
};

const EquipmentItem = ({
    equipmentId,
    onClick,
}: {
    equipmentId: string;
    onClick: () => void;
}) => {
    const apiClient = useInfrastructureApiClient();
    const equipmentQuery = useQuery({
        queryKey: ["equipment", equipmentId],
        queryFn: async () => {
            const response = await apiClient.infrastructureEquipmentRetrieve({
                id: equipmentId,
            });
            return response;
        },
        staleTime: Infinity,
    });

    if (!equipmentQuery.data) {
        return (
            <div className="w-32 py-2 flex items-center justify-center">
                <FontAwesomeIcon
                    icon={faSpinner}
                    className="w-4 animate-spin"
                />
            </div>
        );
    }

    return (
        <button
            className="px-2 py-1 rounded flex flex-col hover:bg-gray-100 w-full whitespace-nowrap"
            onClick={onClick}
        >
            <span className="font-semibold text-left">
                {/* TODO: Fix equipment name */}
                {equipmentQuery.data.operatorUniqueId}
            </span>

            <span className="text-neutral-500 italic">
                Open containing site
            </span>
        </button>
    );
};

const PipelineItem = ({
    pipelineId,
    onClick,
}: {
    pipelineId: string;
    onClick: () => void;
}) => {
    const apiClient = useInfrastructureApiClient();
    const pipelineQuery = useQuery({
        queryKey: ["pipeline", pipelineId],
        queryFn: async () => {
            const response = await apiClient.infrastructurePipelineRetrieve({
                id: pipelineId,
            });
            return response;
        },
        staleTime: Infinity,
    });

    if (!pipelineQuery.data) {
        return (
            <div className="w-32 py-2 flex items-center justify-center">
                <FontAwesomeIcon
                    icon={faSpinner}
                    className="w-4 animate-spin"
                />
            </div>
        );
    }

    return (
        <button
            className="px-2 py-1 rounded flex flex-col hover:bg-gray-100 w-full whitespace-nowrap"
            onClick={onClick}
        >
            <span className="font-semibold text-left">
                {pipelineQuery.data.lineId}
            </span>
            <span className="text-gray-600 text-left">
                {pipelineQuery.data.pipelineProduct}
                {" - "}
                {pipelineQuery.data.pipelineType}
            </span>
        </button>
    );
};

const EmissionItem = ({
    emissionId,
    dataPointId,
    onClick,
}: {
    emissionId?: string;
    dataPointId?: string;
    onClick: () => void;
}) => {
    const emissionsApiClient = useEmissionRecordsApiClient();
    const datapointsApiClient = useDataPointsApi();

    const emissionQuery = useQuery({
        queryKey: ["emission", emissionId],
        queryFn: async () => {
            const response = await emissionsApiClient.emissionRecordsRetrieve({
                id: emissionId,
            });
            return response;
        },
        staleTime: Infinity,
        enabled: !!emissionId,
    });

    const dataPointQuery = useQuery({
        queryKey: ["datapoint", dataPointId],
        queryFn: async () => {
            const response = await datapointsApiClient.dataPointsRetrieve({
                id: dataPointId,
            });
            return response;
        },
        staleTime: Infinity,
        enabled: !!dataPointId,
    });

    if (!dataPointQuery.data && !emissionQuery.data) {
        return (
            <div className="w-32 py-2 flex items-center justify-center">
                <FontAwesomeIcon
                    icon={faSpinner}
                    className="w-4 animate-spin"
                />
            </div>
        );
    }

    const provider =
        dataPointQuery.data?.providerName || emissionQuery.data?.providerName;
    const rate =
        dataPointQuery.data?.detectedRate ||
        emissionQuery.data?.dataPoint.detectedRate;
    const concentration =
        dataPointQuery.data?.concentration ||
        emissionQuery.data?.dataPoint.concentration;
    const timestamp =
        dataPointQuery.data?.detectionTimestamp ||
        emissionQuery.data?.dataPoint.detectionTimestamp;

    return (
        <button
            className="px-2 py-1 rounded flex flex-col hover:bg-gray-100 w-full whitespace-nowrap"
            onClick={onClick}
        >
            <span className="font-semibold text-left">
                {DateTime.fromJSDate(timestamp)
                    .setZone("utc")
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
            </span>
            <span className="text-gray-600 text-left">
                {provider}
                {" - "}
                {rate && `${(rate / 1000).toFixed(1)} kg/h`}
                {concentration && `${concentration.toFixed(1)} ppm*m`}
            </span>
        </button>
    );
};

interface MenuItemProps {
    label: string;
    count: number;
    children: React.ReactNode;
}

const MenuItem = ({ label, count, children }: MenuItemProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles } = useFloating({
        placement: "right-start",
        middleware: [
            offset({
                crossAxis: -4,
            }),
            shift(),
            flip(),
        ],
    });

    return (
        <div className="relative">
            <div
                ref={refs.setReference}
                className="py-1 px-2 w-full flex items-center text-sm justify-between rounded hover:bg-gray-100 cursor-pointer"
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                <span className="w-full">{label}</span>
                <span className="text-[#595959] text-sm mx-3">{count}</span>
                <FontAwesomeIcon icon={faChevronRight} className="h-3" />
            </div>
            {isOpen && (
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="absolute bg-transparent px-2"
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                >
                    <div className="bg-white rounded shadow-lg p-1 max-h-72 overflow-auto">
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};

interface MapPopupProps {
    picked: PickedItems;
    onClickItem: (item: PickingInfo) => void;
}

/**
 * Map Popup: when overlapping points are clicked.
 *
 * FIXME: Pop up doesn't follow map point.
 */
export const MapPopup = (props: MapPopupProps) => {
    const { picked } = props;
    const totalPickedCount =
        picked.emissions.length +
        picked.dataPoints.length +
        picked.sites.length +
        picked.equipment.length +
        picked.pipelines.length;

    return (
        <div
            className="absolute ml-4 -mt-5"
            style={{
                top: picked.pointClicked.y,
                left: picked.pointClicked.x,
            }}
        >
            <div className="rounded bg-white w-44 flex flex-col text-sm p-1">
                {(picked.emissions.length > 0 ||
                    picked.dataPoints.length > 0) && (
                    <MenuItem
                        label="Emissions"
                        count={
                            picked.emissions.length + picked.dataPoints.length
                        }
                    >
                        {picked.emissions.map((emission) => (
                            <EmissionItem
                                key={emission.object.properties.id}
                                emissionId={emission.object.properties.id}
                                onClick={() => props.onClickItem(emission)}
                            />
                        ))}
                        {picked.dataPoints.map((dataPoint) => (
                            <EmissionItem
                                key={dataPoint.object.properties.id}
                                dataPointId={dataPoint.object.properties.id}
                                onClick={() => props.onClickItem(dataPoint)}
                            />
                        ))}
                    </MenuItem>
                )}

                {picked.sites.length > 0 && (
                    <MenuItem label="Sites" count={picked.sites.length}>
                        {picked.sites.map((site) => (
                            <SiteItem
                                key={site.object.properties.id}
                                siteId={site.object.properties.id}
                                onClick={() => props.onClickItem(site)}
                            />
                        ))}
                    </MenuItem>
                )}
                {picked.equipment.length > 0 && (
                    <MenuItem label="Equipment" count={picked.equipment.length}>
                        {picked.equipment.map((equipment) => (
                            <EquipmentItem
                                key={equipment.object.properties.id}
                                equipmentId={equipment.object.properties.id}
                                onClick={() => props.onClickItem(equipment)}
                            />
                        ))}
                    </MenuItem>
                )}

                {picked.pipelines.length > 0 && (
                    <MenuItem label="Pipelines" count={picked.pipelines.length}>
                        {picked.pipelines.map((pipeline) => (
                            <PipelineItem
                                key={pipeline.object.properties.id}
                                pipelineId={pipeline.object.properties.id}
                                onClick={() => props.onClickItem(pipeline)}
                            />
                        ))}
                    </MenuItem>
                )}

                {/* NOTE: Make sure this comparison is equal to `customPickingLimit` set on map */}
                {totalPickedCount == 20 && (
                    <div className="text-xs text-neutral-600 w-full flex items-center justify-between bg-yellow-100 px-2 py-1 rounded">
                        Not all results will be shown (max. 20).
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className="w-3"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
