/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmissionStatusEnum } from './EmissionStatusEnum';
import {
    EmissionStatusEnumFromJSON,
    EmissionStatusEnumFromJSONTyped,
    EmissionStatusEnumToJSON,
} from './EmissionStatusEnum';

/**
 * Serializer for updating data for EmissionMatch model.
 * @export
 * @interface EmissionRecordUpdate
 */
export interface EmissionRecordUpdate {
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordUpdate
     */
    readonly id: string;
    /**
     * 
     * @type {EmissionStatusEnum}
     * @memberof EmissionRecordUpdate
     */
    emissionStatus: EmissionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordUpdate
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordUpdate
     */
    equipment?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordUpdate
     */
    infrastructure?: string;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordUpdate
     */
    infrastructureDetail?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmissionRecordUpdate
     */
    events?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmissionRecordUpdate
     */
    rejectReason?: string;
}

/**
 * Check if a given object implements the EmissionRecordUpdate interface.
 */
export function instanceOfEmissionRecordUpdate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('emissionStatus' in value)) return false;
    return true;
}

export function EmissionRecordUpdateFromJSON(json: any): EmissionRecordUpdate {
    return EmissionRecordUpdateFromJSONTyped(json, false);
}

export function EmissionRecordUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmissionRecordUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'emissionStatus': EmissionStatusEnumFromJSON(json['emission_status']),
        'site': json['site'] == null ? undefined : json['site'],
        'equipment': json['equipment'] == null ? undefined : json['equipment'],
        'infrastructure': json['infrastructure'] == null ? undefined : json['infrastructure'],
        'infrastructureDetail': json['infrastructure_detail'] == null ? undefined : json['infrastructure_detail'],
        'events': json['events'] == null ? undefined : json['events'],
        'rejectReason': json['reject_reason'] == null ? undefined : json['reject_reason'],
    };
}

export function EmissionRecordUpdateToJSON(value?: Omit<EmissionRecordUpdate, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emission_status': EmissionStatusEnumToJSON(value['emissionStatus']),
        'site': value['site'],
        'equipment': value['equipment'],
        'infrastructure': value['infrastructure'],
        'infrastructure_detail': value['infrastructureDetail'],
        'events': value['events'],
        'reject_reason': value['rejectReason'],
    };
}

